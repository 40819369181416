/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CardPharmacistInfo from "../../../components/card/CardPharmacistInfo";
import DialogPharmacistInfo from "../../../components/dialog/DialogPharmacistInfo";
import DialogAppointmentHistory from "../../../components/dialog/DialogAppointmentHistory";
import ModalLevelPharmacist from "../../../components/modal/ModalLevelPharmacist";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  getPharmacistProfileApi,
  updateStatusPharmacistApi,
  updateLevelPharmacistApi,
  managePharmacistApi,
} from "../../../services/apiList";
import { loadingTogether } from "../../../../_metronic/_helpers";
import utils from "../../../utils";
import {
  activePharmacist,
  MIN_EXPERIENCE_YEAR,
  pendingPharmacist,
  PHARMACY_STATUS_VALUES,
} from "../../../constant";
import ModalRejectPharmacist from "../../../components/modal/ModalRejectPharmacist";

const PharmacistInformation = () => {
  const params: any = useParams();
  const [typeUser, setTypeUser] = useState("1");
  const [indexTab, setIndexTab] = useState("0");
  const [showModal, setShowModal] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [pharmacistInfo, setPharmacistInfo] = useState<any>(null);
  const [showModalView, setShowModalView] = useState(false);
  const { addToast } = useToasts();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleShowModalReject = () => {
    setShowModalReject(true);
  };

  const getPharmacistInfo = async () => {
    try {
      loadingTogether(true);
      const res = await getPharmacistProfileApi(params.pharmacistId);
      if (res.error_code === 0) {
        res.data.appointment_history.sort(utils.sortByDate);
        setPharmacistInfo(res.data);
        if (pendingPharmacist.includes(res.data.status)) {
          setTypeUser("0");
        }
        if (activePharmacist.includes(res.data.status)) {
          setTypeUser("1");
        }
      }
    } catch (error: any) {
      addToast(error?.response?.statusText || error?.response?.data.message, {
        appearance: "error",
      });
    } finally {
      loadingTogether(false);
    }
  };

  const handleUpdateLevelPharmacist = (pharmacistData: any) => async (
    type: string,
    callback: any
  ) => {
    try {
      if (
        type == "2" &&
        pharmacistData?.pharmacist_information?.experience_year < MIN_EXPERIENCE_YEAR
      ) {
        addToast("Pharmacist must have at least 10 years of experience", {
          appearance: "warning",
        });
        return;
      }
      let listId;
      listId = [pharmacistData?.id];
      const res = await updateLevelPharmacistApi(listId, type);
      if (res.error_code === 0) {
        getPharmacistInfo();
      }
    } catch (error: any) {
      addToast(
        error.message ||
          error?.response?.statusText ||
          error?.response?.data.message,
        { appearance: "error" }
      );
    } finally {
      if (callback) {
        callback();
      }
    }
  };

  const handleManagePharmacist = async (pharmacistData: any, callback: any) => {
    try {
      const status_expect = pharmacistData?.status === PHARMACY_STATUS_VALUES.UNLOCK
      ? PHARMACY_STATUS_VALUES.LOCK : PHARMACY_STATUS_VALUES.UNLOCK;
      const res = await managePharmacistApi(pharmacistData?.id, {
        status: status_expect,
      });
      if (res.error_code === 0) {
        if (callback) {
          callback();
        }
        getPharmacistInfo();
        addToast(
          status_expect === PHARMACY_STATUS_VALUES.LOCK
            ? "Lock pharmacist successfully"
            : "Unlock pharmacist successfully",
          { appearance: "success" }
        );
      }
    } catch (error: any) {
      addToast(error?.response?.statusText || error?.response?.data.message, {
        appearance: "error",
      });
    }
  };

  const handleUpdateStatusPharmacist = (
    status_expect: number | string,
    pharmacistData: any
  ) => async (note: string) => {
    try {
      let listId = [pharmacistData?.id];

      const res = await updateStatusPharmacistApi(listId, status_expect, note);
      if (res.error_code === 0) {
        getPharmacistInfo();
        addToast("Refuse pharmacist successfully", { appearance: "success" });
      }
    } catch (error: any) {
      addToast(error?.response?.statusText || error?.response?.data.message, {
        appearance: "error",
      });
    } finally {
      setShowModalReject(false);
    }
  };

  useEffect(() => {
    getPharmacistInfo();
  }, []);

  return (
    <Grid container spacing={1}>
      <ModalRejectPharmacist
        show={showModalReject}
        handleClose={() => setShowModalReject(false)}
        headerTitle="State your reasons for refusing this pharmacist"
        handleRejectPharmacist={(note: string) =>
          handleUpdateStatusPharmacist(
            PHARMACY_STATUS_VALUES.NOT_ACCEPT,
            pharmacistInfo
          )(note)
        }
      />
      <ModalLevelPharmacist
        show={showModal}
        handleClose={handleClose}
        headerTitle="Level setting for this pharmacist !"
        handleUpdateLevelPharmacist={handleUpdateLevelPharmacist(
          pharmacistInfo
        )}
      />
      <Grid item xs={5}>
        <CardPharmacistInfo
          typeUser={typeUser}
          setIndexTab={setIndexTab}
          {...pharmacistInfo}
        />
      </Grid>
      <Grid item xs={7}>
        {indexTab === "0" ? (
          <DialogPharmacistInfo
            typeUser={typeUser}
            handleShowModal={handleShowModal}
            handleShowModalReject={handleShowModalReject}
            pharmacistInfo={pharmacistInfo}
            handleManagePharmacist={handleManagePharmacist}
          />
        ) : (
          <DialogAppointmentHistory
            listAppointment={pharmacistInfo?.appointment_history}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PharmacistInformation;
