import { ISeriesChartColumn } from "../services/types";

const COLOR_APPOINTMENT_STATUS: { [field: string]: any } = {
  1: { color: "#FADB14", title: "Waiting" },
  2: { color: "#F95D5D", title: "Cancel" },
  3: { color: "#1890FF", title: "Ongoing" },
  4: { color: "#4ECB73", title: "Finish" },
  5: { color: "#6c5ce7", title: "Expired" },
  6: { color: `#e67e22`, title: "Unpaid" },
};

const PHARMACY_STATUS_VALUES = {
  UNLOCK: 1,
  LOCK: 7,
  WAITING: 3,
  NOT_ACCEPT: 4,
};

const PATIENT_STATUS_VALUES = {
  UNLOCK: 1,
  LOCK: 7,
};

const initDataChartColumn: {
  dataSeries: ISeriesChartColumn[];
  dataLabels: string[];
  [fieldName: string]: any;
} = {
  dataSeries: [],
  dataLabels: [],
};

const COLOR_USER_STATUS: { [field: string]: any } = {
  1: { color: "#4ECB73", title: "Unlock" },
  2: { color: "#F95D5D", title: "Lock" },
  3: { color: "#FADB14", title: "Waiting" },
  4: { color: "#F95D5D", title: "Not accept" },
};

const activePharmacist = [1, 2];
const pendingPharmacist = [3, 4];

const BACKGROUND_COLOR_DARK_LAYOUT = "#343E59";

const listButtonFilter = [
  { color: "", title: "All" },
  { color: "#FADB14", title: "Waiting" },
  { color: "#F95D5D", title: "Cancel" },
  { color: "#1890FF", title: "Ongoing" },
  { color: "#4ECB73", title: "Finish" },
];

const LIST_SUPPORT_FILE = ["pdf", "doc", "docx", "xlsx", "mp4", "webm", "mp3"];

const MIN_EXPERIENCE_YEAR = 10;

export {
  BACKGROUND_COLOR_DARK_LAYOUT,
  COLOR_APPOINTMENT_STATUS,
  PHARMACY_STATUS_VALUES,
  COLOR_USER_STATUS,
  listButtonFilter,
  initDataChartColumn,
  activePharmacist,
  pendingPharmacist,
  LIST_SUPPORT_FILE,
  MIN_EXPERIENCE_YEAR,
  PATIENT_STATUS_VALUES,
};
