/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import DialogAppointmentInfo from "../../../components/dialog/DialogAppointmentInfo";
import CardAppointmentInfo from "../../../components/card/CardAppointmentInfo";
import { getAppointmentDetailApi } from "../../../services/apiList";
import { loadingTogether } from "../../../../_metronic/_helpers";

const AppointmentInfo = () => {
  const params: any = useParams();
  const [appointmentData, setAppointmentData] = useState<any>(null);
  const { addToast } = useToasts();

  const getAppointmentInfo = async () => {
    try {
      loadingTogether(true)
      const res = await getAppointmentDetailApi(params?.appointmentId);
      setAppointmentData(res.data)
    } catch (error:any) {
      addToast(error.message || error?.response?.statusText || error?.response?.data.message, { appearance: "success" });
    } finally {
      loadingTogether(false)
    }
  };

  useEffect(() => {
    getAppointmentInfo();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <CardAppointmentInfo appointmentData={appointmentData} />
      </Grid>
      <Grid item xs={7}>
        <DialogAppointmentInfo
          appointmentData={appointmentData}
        />
      </Grid>
    </Grid>
  );
};

export default AppointmentInfo;
