import React from "react";
import { Modal, Button } from "react-bootstrap";
import { TextareaAutosize } from "@material-ui/core";
import "../../asset/scss/modal/modal_reject_pharmacist.scss";
import { useFormik } from "formik";
import { RejectPharmacistSchema } from "../../plugins/validate";

const initialValues: any = {
  note: "",
};

interface IProps {
  show: boolean;
  handleClose: any;
  headerTitle: string;
  handleRejectPharmacist: Function;
}

const ModalRejectPharmacist = ({
  show,
  handleClose,
  headerTitle,
  handleRejectPharmacist,
}: IProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: RejectPharmacistSchema,
    onSubmit: (values: any) => {
      handleRejectPharmacist(values.note);
      formik.resetForm()
    },
  });

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  const handleCloseModal = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      keyboard={false}
      dialogClassName="modal_reject_pharmacist"
      centered
    >
      <Modal.Header style={{ borderBottom: "none" }}>
        <Modal.Title>
          {headerTitle}
          <span style={{ color: "red" }}>*</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body_reject w-100">
          <TextareaAutosize
            rowsMax={5}
            rowsMin={5}
            placeholder="Please reason for reject pharmacist"
            className={
              !formik.errors.note || !formik.touched.note
                ? "textarea_reject"
                : "textarea_reject input-error"
            }
            {...formik.getFieldProps("note")}
          />
          {formik.touched.note && formik.errors.note ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.note}</div>
            </div>
          ) : (
            <div className="fv-plugins-message-container" />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={handleCloseModal}>
          No
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRejectPharmacist;
