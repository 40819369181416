import AccountType from "./type";
interface IAction {
  type: string;
  data: any;
}

export type IAccountState = {
  avatar: string | null;
  avatar_thumb: string | null;
  preferred_name: string | null;
};

const initialState: any = {
  avatar: null,
  avatar_thumb: null,
  preferred_name: null,
};

const accountReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case AccountType.UPDATE_INFO:
      return { ...state, ...action.data };

    case AccountType.RESET_INFO:
      return initialState;
    default:
      return state;
  }
};

export default accountReducer;
