import AccountType from "./type";

interface IData {
  avatar: string | null;
  avatar_thumb: string | null;
  preferred_name: string | null;
}

const updateInfoAcc = (data: IData) => ({
  type: AccountType.UPDATE_INFO,
  data,
});

const resetInfoAcc = () => ({
  type: AccountType.UPDATE_INFO,
});

export { updateInfoAcc, resetInfoAcc };
