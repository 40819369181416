import React from "react";
import TablePatientManagement from "../../../components/table/TablePatientManagement";
import "../styles/patient_manage.scss";

const PatientManage = () => {
  return (
    <div className="patient-manage-container bg-white">
      <div className="title-container">Patient account management</div>
      <TablePatientManagement />
    </div>
  );
};

export default PatientManage;
