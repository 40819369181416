import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { COLOR_APPOINTMENT_STATUS } from "../../constant";
import { useHistory } from "react-router";
import "../../asset/scss/card_appointment_history.scss";
import utils from "../../utils";

interface IProps {
  listAppointment: any;
}

const DialogAppointmentHistory = ({ listAppointment }: IProps) => {
  const history = useHistory();

  const handleNavigateAppointment = (appointmentId: number) => {
    history.push(`/appointment-info/${appointmentId}`);
  };

  const renderItem = ({ item, index }: any) => {
    const colorIcon = COLOR_APPOINTMENT_STATUS[item?.appointment_status].color;
    return (
      <div
        className="appointment-item"
        key={index}
        onClick={() => handleNavigateAppointment(item?.id)}
      >
        <img
          src={utils.getUrlImageServer(item?.patient?.avatar_thumb)}
          alt="avatar_patient"
          className="avatar-pharmacist"
        />
        <div className="list-title-field">
          <div className="field-text">Patient:</div>
          <div className="field-text">Appointed time:</div>
          <div className="field-text">Status:</div>
        </div>
        <div className="list-description">
          <div className="field-text pharmacist">
            {item?.patient?.preferred_first_name ||
              `${item?.patient?.first_name} ${item?.patient?.last_name || ""}`}
          </div>
          <div className="field-text appoint-time">
            {item?.appointment_date
              ? utils.formatDate(item?.appointment_date)
              : "No"}
          </div>
          <div className="field-text ">
            <FiberManualRecordIcon style={{ color: colorIcon, fontSize: 12 }} />
            <span className="ml-1">
              {COLOR_APPOINTMENT_STATUS[item?.appointment_status].title}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card-appointment-history bg-white">
      <div className="header-card">
        <div className="title-header">Appointment history</div>
      </div>
      <div className="content-card">
        {listAppointment.map((item: any, index: number) =>
          renderItem({ item, index })
        )}
      </div>
    </div>
  );
};

export default DialogAppointmentHistory;
