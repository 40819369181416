import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import ErrorBoundary from "../../../../app/container/NotFound/ErrorBoundary";

interface IPropsContent {
  children: React.ReactNode;
}

export function Content({ children }: IPropsContent) {
  const match = useRouteMatch();
  const animationEndClass = "grid-animateContent-finished";
  const [cssClassesState, setCssClassesState] = useState([
    "grid-animateContent",
    animationEndClass,
  ]);

  useEffect(() => {
    const fullClasses = [...cssClassesState];
    const startAnimation = fullClasses.filter((el) => el !== animationEndClass);
    setCssClassesState(startAnimation);
    const timeOutId = setTimeout(() => {
      setCssClassesState(fullClasses);
    }, 200);

    return () => {
      clearTimeout(timeOutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.url]);

  return (
    <>
      <ErrorBoundary>{children}</ErrorBoundary>
    </>
  );
}
