import React, { useState } from "react";
import { useFormik } from "formik";
import { ChangePasswordSchema } from "../../plugins/validate";
import "../../asset/scss/form/form_change_password.scss";
import { changePasswordApi } from "../../services/apiList";

const initialValues: any = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

const FormChangePassword = ({ addToast }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      try {
        setIsLoading(true);
        const res = await changePasswordApi({
          old_password: values.old_password,
          new_password: values.new_password,
        });
        if (res.error_code === 0) {
          addToast("Change password successfully", { appearance: "success" });
        }
      } catch (error:any) {
        addToast(error.message || error?.response?.statusText || error?.response?.data.message, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleChangePassword = () => {
    formik.handleSubmit();
  };

  return (
    <div className="form-account-info bg-white">
      <div className="header-form">Change Password</div>
      <div className="body-form">
        <div className="form-group">
          <div className="label-input-form">
            Old password <span className="required-field">*</span>
          </div>
          <input
            type="password"
            className={`input-form-group${
              formik.errors.old_password ? " error-input" : ""
            }`}
            {...formik.getFieldProps("old_password")}
          />
          {formik.touched.old_password && formik.errors.old_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.old_password}</div>
            </div>
          ) : (
            <div className="fv-plugins-message-container" />
          )}
        </div>
        <div className="form-group">
          <div className="label-input-form">
            New password <span className="required-field">*</span>
          </div>
          <input
            type="password"
            className={`input-form-group${
              formik.errors.new_password ? " error-input" : ""
            }`}
            {...formik.getFieldProps("new_password")}
          />
          {formik.touched.new_password && formik.errors.new_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.new_password}</div>
            </div>
          ) : (
            <div className="fv-plugins-message-container" />
          )}
        </div>
        <div className="form-group">
          <div className="label-input-form">
            Confirm new password <span className="required-field">*</span>
          </div>
          <input
            type="password"
            className={`input-form-group${
              formik.errors.confirm_password ? " error-input" : ""
            }`}
            {...formik.getFieldProps("confirm_password")}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirm_password}</div>
            </div>
          ) : (
            <div className="fv-plugins-message-container" />
          )}
        </div>
      </div>
      <div className="footer-form">
        <button
          className={`btn btn-primary btn-save ${isLoading &&
            "btn-disable"}`}
          onClick={handleChangePassword}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default FormChangePassword;
