/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../asset/scss/table/table_patient_manage.scss";
import paginationFactory from "react-bootstrap-table2-paginator";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  downloadCSVPharmacistApi,
  managePharmacistApi,
} from "../../services/apiList";
import utils from "../../utils";
import ModalConfirm from "../modal/ModalConfirm";
import { PHARMACY_STATUS_VALUES } from "../../constant";

const TablePharmacistManage = ({
  getListPharmacistManage,
  dataFromApi,
  totalData,
  setDataFromApi,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectUser, setSelectUser] = useState(null);
  const [textSearch, setTextSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const { addToast } = useToasts();

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      style: {
        width: "5%",
        fontWeight: "500",
        fontSize: "15px",
      },
    },
    {
      dataField: "avatar_thumb",
      text: "Avatar",
      formatter: avatarFormatter,
      style: {
        width: "5%",
      },
    },
    {
      dataField: "full_name",
      text: "Full Name",
      sort: true,
      formatter: fullNameFormatter,
      style: {
        width: "20%",
        wordBreak: "break-word",
      },
    },
    {
      dataField: "phone_number",
      text: "Phone number",
      // sort: true,
      formatter: phoneNumberFormatter,
      style: {
        width: "15%",
        textAlign: "left",
      },
    },
    {
      dataField: "license_state",
      text: "Practicing certificate",
      // sort: true,
      formatter: certificateFormatter,
      style: {
        width: "20%",
        textAlign: "left",
      },
    },
    {
      dataField: "experience",
      text: "Experience",
      sort: true,
      formatter: experienceFormatter,
      style: {
        width: "10%",
        textAlign: "left",
      },
    },
    {
      dataField: "status",
      text: "Status",
      // sort: true,
      formatter: statusFormatter,
      style: {
        width: "15%",
        textAlign: "left",
      },
    },
    {
      dataField: "function",
      text: "Function",
      style: {
        width: "10%",
        textAlign: "left",
      },
      formatter: tagFormatter,
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
  ];

  function avatarFormatter(cell) {
    return (
      <div className="image-avatar">
        <img src={utils.getUrlImageServer(cell)} alt="avatar-user" />
      </div>
    );
  }

  function fullNameFormatter(cell, row) {
    return (
      <>
        <div className="preferred-name">{cell}</div>
        <div className="full-name">
          {row?.preferred_first_name ? `(${row?.preferred_first_name})` : ""}
        </div>
      </>
    );
  }

  function phoneNumberFormatter(cell, row) {
    return (
      <>
        <div>{utils.formatPhoneNumber(cell, row?.dial_code)}</div>
      </>
    );
  }

  function statusFormatter(cell, row) {
    const colorIcon = row?.status !== 1 ? "#F95D5D" : "#4ECB73";

    return (
      <>
        <FiberManualRecordIcon style={{ color: colorIcon, fontSize: 12 }} />
        <span className="ml-1">{row?.status !== 1 ? "Lock" : "Unlock"}</span>
      </>
    );
  }

  function experienceFormatter(cell, row) {
    const experience = row.pharmacist_information?.experience_year;
    return (
      <>
        <div>{experience ? `${experience} years` : "-"} </div>
      </>
    );
  }

  function certificateFormatter(cell, row) {
    return cell.length > 0 ? (
      <>
        <div>
          {cell.map((item, index) => (
            <div key={item.id}>
              {item.state.state_name} - {item.number}
            </div>
          ))}
        </div>
      </>
    ) : (
      <>
        <div>No</div>
      </>
    );
  }

  function tagFormatter(cell, row) {
    return (
      <>
        <button className="btn btn-primary" onClick={() => handleAction(row)}>
          {row?.status === 1 ? <LockOpenIcon /> : <LockIcon />}
        </button>
      </>
    );
  }

  const NoDataIndication = () => {
    return <div className="text-nodata"> No record </div>;
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setShow(true);
      history.push(`/pharmacist-info/${row?.id}`);
    },
  };

  const handleManagePharmacist = async () => {
    try {
      const status_expect = selectUser.status === PHARMACY_STATUS_VALUES.UNLOCK ?
      PHARMACY_STATUS_VALUES.LOCK : PHARMACY_STATUS_VALUES.UNLOCK;
      const res = await managePharmacistApi(selectUser?.id, {
        status: status_expect,
      });
      if (res.error_code === 0) {
        setSelectUser(null);
        setShow(false);
        setPage(1);
        getListPharmacistManage(1, sizePage, "");
        addToast(
          status_expect === PHARMACY_STATUS_VALUES.LOCK
            ? "Lock pharmacist successfully"
            : "Unlock pharmacist successfully",
          { appearance: "success" }
        );
      }
    } catch (error) {
      addToast(error?.response?.statusText || error?.response?.data.message, {
        appearance: "error",
      });
    }
  };

  const handleAction = (user) => {
    setShow(true);
    setSelectUser(user);
  };

  const handleClose = () => {
    setShow(false);
    setSelectUser(null);
  };

  const handleChangeText = (e) => {
    setTextSearch(e?.target?.value);
  };

  const searchByKeyword = () => {
    setPage(1);
    getListPharmacistManage(1, sizePage, textSearch);
  };

  const handleExport = async () => {
    try {
      const params = {
        search: textSearch,
        page,
        page_size: sizePage,
      };
      await downloadCSVPharmacistApi(params);
    } catch (error) {
      addToast(error?.response?.statusText || error?.response?.data.message, {
        appearance: "error",
      });
    }
  };

  const onTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePage(newState.sizePerPage);
    if (type === "pagination") {
      getListPharmacistManage(newState.page, newState.sizePerPage, textSearch);
      return;
    }
    let result;
    if (newState.sortOrder === "asc") {
      result = newState.data.slice().sort((a, b) => {
        if (newState.sortField === "full_name") {
          return b[newState.sortField]?.localeCompare(a[newState.sortField]);
        }
        if (newState.sortField === "experience") {
          return (
            Number(b?.pharmacist_information?.experience_year ?? 0) -
            Number(a?.pharmacist_information?.experience_year ?? 0)
          );
        }
        if (a[newState.sortField] > b[newState.sortField]) {
          return 1;
        } else if (b[newState.sortField] > a[newState.sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = newState.data.slice().sort((a, b) => {
        if (newState.sortField === "full_name") {
          return a[newState.sortField]?.localeCompare(b[newState.sortField]);
        }
        if (newState.sortField === "experience") {
          return (
            Number(a?.pharmacist_information?.experience_year ?? 0) -
            Number(b?.pharmacist_information?.experience_year ?? 0)
          );
        }
        if (a[newState.sortField] > b[newState.sortField]) {
          return -1;
        } else if (b[newState.sortField] > a[newState.sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setDataFromApi(result);
  };

  useEffect(() => {
    getListPharmacistManage();
  }, []);

  return (
    <>
      <ModalConfirm
        show={show}
        onHide={handleClose}
        headerTitle={
          selectUser?.status === 1
            ? "Do you want to lock this pharmacist account?"
            : "Do you want to unlock this pharmacist account?"
        }
        handleConfirm={handleManagePharmacist}
      />
      <div className="table-manage-post">
        <div className="body-manage-post data-table">
          <div className="d-flex justify-content-between align-items-center pb-1 header-table">
            <div className="wrapper-input-search">
              <div className="position-relative input-container">
                <input
                  type="text"
                  name="searchText"
                  placeholder="Search for account"
                  value={textSearch}
                  onChange={handleChangeText}
                  autoComplete="off"
                />
                <i className="flaticon-search icon-md icon-search" />
              </div>
              <div className="wrapper-search">
                <button
                  className="btn btn-primary btn-search"
                  onClick={searchByKeyword}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="wrapper-actions-table">
              <button className="btn btn-export" onClick={handleExport}>
                Export
              </button>
            </div>
          </div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            key={"pharmacist_manage_table"}
            keyField="id"
            remote={{
              filter: true,
              pagination: true,
              sort: false,
              cellEdit: false,
            }}
            page={page}
            sizePerPage={sizePage}
            data={dataFromApi}
            columns={columns}
            rowEvents={rowEvents}
            pagination={paginationFactory({
              totalSize: totalData,
              page: page,
              sizePerPage: sizePage,
            })}
            noDataIndication={() => <NoDataIndication />}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default TablePharmacistManage;
