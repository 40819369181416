/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, useLocation, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers/index";
import "../container/Auth/styles/login.scss";
import "../container/Auth/styles/reset_password.scss";
import BannerLogin from "../components/banner/BannerLogin";
import Login from "../container/Auth/pages/Login";
import { ContentRoute } from "../../_metronic/layout/components/content/ContentRoute";
import ForgotPassword from "../container/Auth/pages/ForgotPassword";

export function AuthPage() {
  const location = useLocation();
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10">
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              <Link
                to="/"
                className="flex-column-auto mt-5 d-flex justify-content-center align-items-start wrapper-logo"
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/svg/background/bg-12.svg")}
                />
              </Link>
              {location.pathname === "/auth/login" && (
                <BannerLogin type={"login"} />
              )}
              {location.pathname === "/auth/forgot-password" && (
                <BannerLogin type={"forgot_password"} />
              )}
            </div>
          </div>
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
