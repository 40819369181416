/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TablePharmacistModeration from "../../../components/table/TablePharmacistModeration";
import TablePharmacistManage from "../../../components/table/TablePharmacistManage";
import "../styles/index.scss";
import {
  getPharmacistManageApi,
  getPharmacistModerationApi,
} from "../../../services/apiList";
import { useToasts } from "react-toast-notifications";
import { loadingTogether } from "../../../../_metronic/_helpers";

const PharmacistManagement = () => {
  const { addToast } = useToasts();
  const [dataFromApi, setDataFromApi] = useState<any>({
    moderation: {
      data_table: [],
      count: 0,
    },
    managing: {
      data_table: [],
      count: 0,
    },
  });

  const getPharmacistModeration = async (
    page: number,
    sizePage: number,
    textSearch: string
  ) => {
    try {
      const params = {
        search: textSearch,
        page,
        page_size: sizePage,
      };
      const res = await getPharmacistModerationApi(params);
      const formattedData = res.data?.results?.map((item: any) => {
        const full_name = `${item?.first_name ?? ""} ${item?.last_name ?? "_"}`;
        return { ...item, full_name };
      });
      setDataFromApi((dataPrev: any) => ({
        ...dataPrev,
        moderation: {
          data_table: formattedData,
          count: res.data?.count,
        },
      }));
    } catch (error: any) {
      addToast(
          error?.response?.statusText ||
          error?.response?.data.message,
        { appearance: "error" }
      );
    }
  };

  const getListPharmacistManage = async (
    page: number,
    sizePage: number,
    textSearch: string
  ) => {
    try {
      const params = {
        search: textSearch,
        page,
        page_size: sizePage,
      };
      const res = await getPharmacistManageApi(params);
      const formattedData = res.data?.results?.map((item: any) => {
        const full_name = `${item?.first_name ?? ""} ${item?.last_name ?? "_"}`;
        return { ...item, full_name };
      });
      setDataFromApi((dataPrev: any) => ({
        ...dataPrev,
        managing: {
          data_table: formattedData,
          count: res.data?.count,
        },
      }));
    } catch (error: any) {
      addToast(
          error?.response?.statusText ||
          error?.response?.data.message,
        { appearance: "error" }
      );
    }
  };

  const getDataPharmacist = async () => {
    try {
      loadingTogether(true);
      await getPharmacistModeration(1, 10, "");
      await getListPharmacistManage(1, 10, "");
    } catch (error: any) {
      addToast(
          error?.response?.statusText ||
          error?.response?.data.message,
        { appearance: "error" }
      );
    } finally {
      loadingTogether(false);
    }
  };

  const setDataEachTable = (nameTable: string) => (dataTable: any) => {
    setDataFromApi({
      ...dataFromApi,
      [nameTable]: { ...dataFromApi[nameTable], data_table: dataTable },
    });
  };

  useEffect(() => {
    getDataPharmacist();
  }, []);

  return (
    <div className="pharmacist-manage-wrapper">
      <div className="pharmacist-moderation-container bg-white">
        <div className="title-container">Pharmacist account moderation</div>
        <TablePharmacistModeration
          getPharmacistModeration={getPharmacistModeration}
          totalData={dataFromApi.moderation.count}
          dataFromApi={dataFromApi.moderation.data_table}
          getDataPharmacist={getDataPharmacist}
          setDataFromApi={setDataEachTable("moderation")}
        />
      </div>

      <div className="pharmacist-manage-container bg-white">
        <div className="title-container">Pharmacist account management</div>
        <TablePharmacistManage
          getListPharmacistManage={getListPharmacistManage}
          totalData={dataFromApi.managing.count}
          dataFromApi={dataFromApi.managing.data_table}
          setDataFromApi={setDataEachTable("managing")}
        />
      </div>
    </div>
  );
};

export default PharmacistManagement;
