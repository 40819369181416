import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers/AssetsHelpers'
import { LoginSchema } from '../../../plugins/validate'
import { loginApi } from '../../../services/apiList'
import { updateInfoAcc } from '../../../store/account/action'
import '../styles/login.scss'
interface IInfoLogin {
  username: string
  password: string
  [fieldName: string]: string
}

const initialValues: IInfoLogin = {
  username: '',
  password: '',
}

function Login() {
  const [loading, setLoading] = useState(false)
  // const [isRemember, setIsRemember] = useState(false);
  const [isError, setIsError] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values: any) => {
      setIsError(false)
      enableLoading()
      try {
        const response = await loginApi({
          email: values.username,
          password: values.password,
        })

        if (response.error_code === 0) {
          localStorage.setItem('jwtToken', response.data.token)
          dispatch(
            updateInfoAcc({
              avatar: response.data.profile.avatar,
              avatar_thumb: response.data.profile.avatar_thumb,
              preferred_name: response.data.profile.preferred_first_name,
            })
          )
          disableLoading()
          history.push('/')
        }
      } catch (err) {
        setIsError(true)
      } finally {
        disableLoading()
      }
    },
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const onForgotPass = () => {
    history.push('/auth/forgot-password')
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20 container-title">
        <img
          alt="Logo"
          className="footer-background"
          src={toAbsoluteUrl('/media/svg/background/bg-12.svg')}
        />
        <p className="text-center mt-5 h1 name-company">IMPACTPHARM</p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        autoComplete="new-password"
      >
        <div className="form-group fv-plugins-icon-container">
          <div className="container-input">
            <img
              src={toAbsoluteUrl('/media/svg/icons/Auth/username.svg')}
              alt=""
              className="icon-input"
            />
            <TextField
              id="username"
              placeholder="Username"
              variant="outlined"
              className="mt-4 text-input"
              error={!formik.errors.username || !formik.touched.username ? false : true}
              {...formik.getFieldProps('username')}
            />
          </div>
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : (
            <div className="fv-plugins-message-container" />
          )}
        </div>
        <div className="form-group fv-plugins-icon-container mb-0">
          <div className="container-input">
            <img
              src={toAbsoluteUrl('/media/svg/icons/Auth/password.svg')}
              alt=""
              className="icon-input"
            />
            <TextField
              type="password"
              placeholder="Password"
              variant="outlined"
              className="mt-4 text-input"
              error={!formik.errors.password || !formik.touched.password ? false : true}
              {...formik.getFieldProps('password')}
            />
          </div>

          {(formik.touched.password && formik.errors.password) || isError ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {isError ? 'Incorrect email and password' : formik.errors.password}
              </div>
            </div>
          ) : (
            <div className="fv-plugins-message-container" />
          )}
        </div>
        <div className="form-group d-flex flex-row justify-content-between align-items-center container-footer">
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={isRemember}
                onChange={handleChange}
                name="remember_acc"
                color="primary"
              />
            }
            label="Remember account"
          /> */}
          <div className="forgot_pass" onClick={onForgotPass}>
            <p>Forgot password</p>
          </div>
        </div>

        <div className="form-group d-flex flex-wrap justify-content-start align-items-center ">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting && loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span className="font-size-lg font-weight-bolder">Log In</span>
            {loading && <span className="ml-3 spinner spinner-white" />}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Login
