/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { resetInfoAcc } from "../../../../../app/store/account/action";
import { logoutApi } from "../../../../../app/services/apiList";
import utils from "../../../../../app/utils";

export function UserProfileDropdown() {
  const history = useHistory();
  const dispatch = useDispatch();
  const accountInfo = useSelector((state) => state?.account);

  const logout = async () => {
    try {
      await logoutApi();
    } catch (error) {
      console.log(error);
    } finally {
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("user_id");
      sessionStorage.removeItem("full_name");
      localStorage.removeItem("jwtToken");
      dispatch(resetInfoAcc());
      history.push("/auth/login");
    }
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
          }
        >
          <span
            className="symbol symbol-35 symbol-light-success mr-3"
            style={{ width: "35px", height: "35px" }}
          >
            <img
              src={utils.getUrlImageServer(accountInfo?.avatar_thumb)}
              className="avatar_account w-100 h-100 rounded-circle"
            />
          </span>
          <span className="font-weight-bold font-size-lg d-none d-md-inline mr-1 text-black">
            {accountInfo.preferred_name ?? "Admin"}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="navi navi-spacer-x-0 pt-5">
          <Dropdown.Item
            as={Link}
            className="navi-item cursor-pointer px-8 "
            to="/account-settings"
          >
            <div className="navi-link">
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Account Setting
                </div>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            className="navi-item cursor-pointer px-8"
            to="/change-password"
          >
            <div className="navi-link">
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Change password
                </div>
              </div>
            </div>
          </Dropdown.Item>
          <div className="navi-separator mt-3"></div>
          <div className="navi-footer px-8 py-5">
            <a
              className="btn btn-light-primary font-weight-bold"
              onClick={logout}
            >
              Sign Out
            </a>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
