import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment";
import "../../asset/scss/dialog/dialog_pharmacist_info.scss";
import Rating from "../rating/Rating";
import "../../asset/scss/dialog/dialog_appointment_info.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { COLOR_APPOINTMENT_STATUS } from "../../constant";
import utils from "../../utils";

const DialogAppointmentInfo = ({ appointmentData }: any) => {
  const dataPharmacist = appointmentData?.pharmacy;
  const dataPatient = appointmentData?.patient;
  return (
    <div className="wrapper-dialog bg-white">
      <div className="header-dialog">
        <div className="title-header">Appointment information</div>
      </div>
      <div className="content-dialog">
        <table>
          <tbody>
            <tr>
              <td className="info-text">Pharmacist:</td>
              <td className="detail-info">
                {dataPharmacist?.preferred_first_name || "No"}
              </td>
            </tr>
            <tr>
              <td className="info-text">Patient:</td>
              <td className="detail-info">
                {dataPatient?.preferred_first_name ||
                  utils.formatFullName(
                    dataPatient?.first_name,
                    dataPatient?.last_name
                  )}
              </td>
            </tr>
            <tr>
              <td className="info-text">Appointment time:</td>
              <td className="detail-info">
                {appointmentData?.appointment_date
                  ? utils.formatDate(appointmentData?.appointment_date)
                  : "No"}
              </td>
            </tr>
            <tr>
              <td className="info-text">Appointed time:</td>
              <td className="detail-info">
                {appointmentData?.appointment_date
                  ? utils.formatDate(appointmentData?.appointment_date)
                  : "No"}
              </td>
            </tr>
            <tr>
              <td className="info-text">Consulting fee:</td>
              <td className="detail-info">
                {appointmentData?.appointment_fee !== null
                  ? `${appointmentData?.appointment_fee} usd/hour`
                  : "No"}
              </td>
            </tr>
            <tr>
              <td className="info-text">Status:</td>
              <td className="detail-info ">
                <div className="d-flex justify-content-start align-items-center">
                  <FiberManualRecordIcon
                    style={{
                      color: appointmentData?.appointment_status
                        ? COLOR_APPOINTMENT_STATUS[
                            appointmentData?.appointment_status
                          ]?.color
                        : "black",
                      fontSize: 12,
                    }}
                  />
                  <span className="ml-1">
                    {appointmentData?.appointment_status
                      ? COLOR_APPOINTMENT_STATUS[
                          appointmentData?.appointment_status
                        ]?.title
                      : "Cancel"}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="info-text">Payment type:</td>
              <td className="detail-info">
                {appointmentData?.payment || "Paypal"}
              </td>
            </tr>
            {appointmentData?.appointment_status === 4 && (
              <tr>
                <td className="info-text">Rating:</td>
                <td className="detail-info">
                  <Rating rating={appointmentData?.rating} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {appointmentData?.appointment_status === 2 && (
          <div className="container-cancel">
            <div className="title-cancel">
              Appointment canceled<span className="text-import">*</span>
            </div>
            <table>
              <tbody>
                <tr>
                  <td className="info-text">Canceller:</td>
                  <td className="detail-info">
                    {appointmentData?.canceled_by &&
                      (appointmentData?.canceled_by?.preferred_first_name ||
                        utils.formatFullName(
                          appointmentData?.canceled_by?.first_name,
                          appointmentData?.canceled_by?.last_name
                        ))}
                    {appointmentData?.canceled_by === null && "Admin"}
                  </td>
                </tr>
                <tr>
                  <td className="info-text">Cancellation time:</td>
                  <td className="detail-info">
                    {moment(new Date(appointmentData?.updated_at)).format(
                      "DD/MM/YYYY hh:mm a"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="info-text">Monetary fine:</td>
                  <td className="detail-info">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <span>
                        {appointmentData?.appointment_canceled?.monetary_fine ??
                          5}
                        $
                      </span>
                      <OverlayTrigger
                        key="tooltip-one"
                        placement="right-end"
                        overlay={
                          <Tooltip id={`tooltip-one`}>
                            Due to cancellation of appointment less than 24
                            hours before meeting time, patient will be charged $
                            {
                              appointmentData?.appointment_canceled
                                ?.monetary_fine
                            }{" "}
                            service fee
                          </Tooltip>
                        }
                      >
                        <i
                          className="flaticon2-exclamation text-white  d-inline-block attention mb-1"
                          style={{ fontSize: 10 }}
                        />
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="info-text">Refunds:</td>
                  <td className="detail-info">
                    {appointmentData?.appointment_canceled?.refund_amount ?? 20}
                    $
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DialogAppointmentInfo;
