import React from "react";
import { Modal, Button } from "react-bootstrap";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }
  goToHome = () => {
    this.props.children.props.history.push("/");
  };

  render() {
    if (this.state.hasError) {
      return (
        <Modal
          show={this.state.hasError}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-lg"
              className={`font-weight-bolder text-danger`}
            >
              <i className={`flaticon-warning text-danger mr-3 icon-lg`} />
              Error
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Something went wrong.</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.goToHome}
              className="font-size-lg font-weight-bolder"
              variant="danger"
            >
              Go home
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return this.props.children;
  }
}
