import React from "react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import "../../asset/scss/card_patient_info.scss";
import { COLOR_USER_STATUS } from "../../constant";
import utils from "../../utils";

const CardPatientInfo = ({
  status,
  first_name,
  last_name,
  phone_number,
  preferred_first_name,
  dial_code,
  setShowModal,
}: any) => {
  const colorIcon = COLOR_USER_STATUS[status]?.color;
  return (
    <div className="wrapper-card bg-white">
      <div className="header-card">
        <div className="title-header">Account information</div>
        <button
          className="btn btn-primary btn-actions-user"
          onClick={() => setShowModal(true)}
        >
          {status === 1 ? <LockOpenIcon /> : <LockIcon />}
        </button>
      </div>
      <div className="content-card">
        <div className="info-wrapper">
          <div className="info-text">Preferred first name:</div>
          <div className="detail-info">{preferred_first_name || "No"}</div>
        </div>
        <div className="info-wrapper">
          <div className="info-text">First and last name:</div>
          <div className="detail-info">
            {utils.formatFullName(first_name, last_name)}
          </div>
        </div>
        <div className="info-wrapper">
          <div className="info-text">Phone number: </div>
          <div className="detail-info">
            {utils.formatPhoneNumber(phone_number, dial_code)}
          </div>
        </div>
        <div className="info-wrapper">
          <div className="info-text">Status: </div>
          <div className="detail-info">
            <FiberManualRecordIcon style={{ color: colorIcon, fontSize: 12 }} />
            <span className="ml-1">{COLOR_USER_STATUS[status]?.title}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPatientInfo;
