import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../asset/scss/modal/modal_level_pharmacist.scss";

const ModalLevelPharmacist = ({
  show,
  handleClose,
  headerTitle,
  handleUpdateLevelPharmacist,
}: any) => {
  const [levelPharmacist, setLevelPharmacist] = useState("1");

  const handleChange = (event: any) => {
    setLevelPharmacist(event.target.value);
  };

  const handleConfirm = () => {
    handleUpdateLevelPharmacist(levelPharmacist, handleClose);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      dialogClassName="modal_level"
      centered
    >
      <Modal.Header style={{ borderBottom: "none" }}>
        <Modal.Title>{headerTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="label-text">
          Level <span className="text-required">*</span>
        </div>
        <select
          value={levelPharmacist}
          className="select-level"
          onChange={handleChange}
        >
          <option value="1">Regular pharmacist</option>
          <option value="2">Senior pharmacist</option>
        </select>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalLevelPharmacist;
