import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import "../../asset/scss/card_pharmacist_info.scss";
import Rating from "../rating/Rating";
import { COLOR_USER_STATUS } from "../../constant";
import utils from "../../utils";

const CardPharmacistInfo = ({
  preferred_first_name,
  first_name,
  last_name,
  typeUser = "0",
  avatar,
  pharmacist_information,
  total_appointment,
  total_certificate,
  setIndexTab,
  status,
  rating,
}: any) => {
  const colorIcon = COLOR_USER_STATUS[status]?.color;
  const currentStatus = COLOR_USER_STATUS[status]?.title;

  return (
    <div className="card-pharmacist-info">
      <div className="box-color">
        <div className="info-container">
          <div className="container-avatar">
            <img
              src={utils.getUrlImageServer(avatar)}
              alt="avatar-user"
              className="avatar-user"
            />
          </div>
          <span className="username">{preferred_first_name ||
              utils.formatFullName(
                first_name,
                last_name
              )}</span>
          {typeUser === "0" ? (
            <div className="status-user">
              <span>Status: </span>
              <span>
                <FiberManualRecordIcon
                  style={{ color: colorIcon, fontSize: 12 }}
                />
                <span className="ml-1">{currentStatus}</span>
              </span>
            </div>
          ) : (
            <Rating rating={rating} />
          )}
        </div>
      </div>
      <div className="card-footer-info">
        <div className="box-info">
          <div className="subtitle-box">Experience</div>
          <span>{pharmacist_information?.experience_year} Years</span>
        </div>
        {typeUser === "0" && (
          <div className="box-info">
            <div className="subtitle-box">Certificate</div>
            <span>{total_certificate}</span>
          </div>
        )}
        {typeUser === "1" && (
          <div className="box-info has-select" onClick={() => setIndexTab(1)}>
            <div className="subtitle-box">Total appointment</div>
            <span>{total_appointment}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardPharmacistInfo;
