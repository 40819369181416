import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import { StatisticalManagement } from "../container/Statistical";
import { AccountInformation, ChangePassword } from "../container/Account";
import { PatientManage } from "../container/PatientManagement";
import { PatientAccount } from "../container/Account";
import {
  PharmacistManagement,
  PharmacistInformation,
} from "../container/PharmacistManage";
import {
  AppointmentInfo,
  AppointmentManage,
} from "../container/AppointmentManagement";

const BasePage = () => {
  return (
    <Switch>
      {<Redirect exact from="/" to="/statistical" />}
      <ContentRoute path="/statistical" component={StatisticalManagement} />
      <ContentRoute path="/account-settings" component={AccountInformation} />
      <ContentRoute path="/change-password" component={ChangePassword} />
      <ContentRoute path="/patient-manage" component={PatientManage} />
      <ContentRoute
        path="/patient-info/:patientId"
        component={PatientAccount}
      />
      <ContentRoute
        path="/pharmacist-manage"
        component={PharmacistManagement}
      />
      <ContentRoute
        path="/pharmacist-info/:pharmacistId"
        component={PharmacistInformation}
      />
      <ContentRoute path="/appointment-manage" component={AppointmentManage} />
      <ContentRoute
        path="/appointment-info/:appointmentId"
        component={AppointmentInfo}
      />
      <Redirect to="/error" />
    </Switch>
  );
};

export default BasePage;
