import * as Yup from "yup";
const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Please enter the correct email address format")
    .min(6, "Email must be at least 6 characters")
    .max(64, "Email can only be up to 64 characters")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password can only be up to 64 characters")
    .required("Password is required"),
});

const ResetPasswordStepOneSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter the correct email address format")
    .min(6, "Email must be at least 6 characters")
    .max(64, "Email can only be up to 64 characters")
    .required("Email is required"),
});

const ResetPasswordStepTwoSchema = Yup.object().shape({
  opt_code: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .required("Verification code is required"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password can only be up to 64 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password can only be up to 64 characters")
    .oneOf([Yup.ref("new_password")], "Passwords don't match!")
    .required("Confirm password is required"),
});

const AccountInfoSchema = Yup.object().shape({
  username: Yup.string()
    .max(64, "Username can only be up to 64 characters")
    .min(5, "Username must be at least 6 characters")
    .required("Please enter username"),
  phone_number: Yup.string()
    .matches(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
      "Phone number is not valid "
    )
    .required("Please enter Phone number"),
});

const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password can only be up to 64 characters")
    .required("Old password code is required"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password can only be up to 64 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password can only be up to 64 characters")
    .oneOf([Yup.ref("new_password")], "Passwords don't match!")
    .required("Confirm password is required"),
});

const RejectPharmacistSchema = Yup.object().shape({
  note: Yup.string()
    .max(255, "Reason of rejection can only be up to 255 characters")
    .required("Reason reject pharmacist is required"),
});

export {
  LoginSchema,
  ResetPasswordStepTwoSchema,
  ResetPasswordStepOneSchema,
  AccountInfoSchema,
  ChangePasswordSchema,
  RejectPharmacistSchema,
};
