import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index";

interface IProps {
  type: String;
}

const BannerLogin = ({ type }: IProps) => {
  const getIconName = () => {
    switch (type) {
      case "login":
        return "bg-medicine";
      case "forgot_password":
        return "bg-forgot-password";
      default:
        return "bg-medicine";
    }
  };

  return (
    <>
      <div className="flex-column-fluid d-lg-flex d-none flex-column justify-content-center text-center banner-login">
        <h2 className="font-size-h0 mb-5 font-weight-bolder">
          Impact Pharm Admin
        </h2>
        <p className="opacity-70">
          Website to manage your pharmacy
          <br />
        </p>
      </div>
      <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
        <img
          alt="Logo"
          className="footer-background"
          src={toAbsoluteUrl(`/media/svg/background/${getIconName()}.svg`)}
        />
      </div>
      {/* <div className="flex-column-auto d-lg-flex justify-content-center mt-0">
        <span className="text-secondary font-size-md font-weight-light">
          <small>&copy;</small> Privacy policy
        </span>
      </div> */}
    </>
  );
};

export default BannerLogin;
