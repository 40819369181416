import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import "../../asset/scss/card_statistical.scss";
import utils from "../../utils";

interface IProps {
  iconName: String;
  titleHeader: String;
  type?: "last month" | "yesterday";
  dataItem: any;
  typeContent: 1 | 2 | 3 | 4;
  hasUser?: boolean;
}
const CardStatistical = ({
  iconName,
  titleHeader,
  dataItem,
  type,
  typeContent,
  hasUser = false,
}: IProps) => {
  return (
    <div className="card-container">
      <div className="header-card">
        <span className="title-header">{titleHeader}</span>
        <div className="container-icon">
          <SVG
            src={toAbsoluteUrl(`/media/svg/icons/Statistical/${iconName}.svg`)}
          />
        </div>
      </div>
      <div className="content-card">
        {typeContent === 1 && (
          <>
            <div className="content-item">
              <p>Patient: {dataItem.patient.data} users</p>
              {hasUser && (
                <div className="footer-change">
                  {dataItem.patient.change_value ? utils.checkIsPositiveNumber(
                    dataItem.patient.change_value
                  ) ? (
                    <ArrowUpwardIcon
                      style={{ color: "#4ECB73", fontSize: 15 }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{ color: "#F95D5D", fontSize: 12 }}
                    />
                  ) : null}
                  <span>{`${Math.abs(dataItem.patient.change_value)}%`}</span>
                </div>
              )}
            </div>
            <div className="content-item">
              <p>Pharmacist: {dataItem.pharmacy.data} users</p>
              {hasUser && (
                <div className="footer-change">
                  {dataItem.pharmacy.change_value ? utils.checkIsPositiveNumber(
                    dataItem.pharmacy.change_value
                  ) ? (
                    <ArrowUpwardIcon
                      style={{ color: "#4ECB73", fontSize: 15 }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{ color: "#F95D5D", fontSize: 12 }}
                    />
                  ) : null}
                  <span>{`${Math.abs(dataItem.pharmacy.change_value)}%` }</span>
                </div>
              )}
            </div>
          </>
        )}
        {typeContent === 2 && (
          <div className="content-item">
            <p>{dataItem.data ? Number(dataItem.data).toFixed(2) : 0} $</p>
          </div>
        )}
        {typeContent === 3 && (
          <div className="content-item">
            <p>{dataItem.data} appointments</p>
          </div>
        )}
      </div>
      {type && (
        <div
          className="footer-card"
          style={{ justifyContent: hasUser ? "flex-end" : "space-between" }}
        >
          {!hasUser&& dataItem.change_value !== 0 && (
            <div className="footer-change">
              {dataItem.change_value ? utils.checkIsPositiveNumber(dataItem.change_value) ? (
                <ArrowUpwardIcon style={{ color: "#4ECB73", fontSize: 15 }} />
              ) : (
                <ArrowDownwardIcon style={{ color: "#F95D5D", fontSize: 12 }} />
              ) : null}
              <span>{`${Math.abs(dataItem.change_value)}%`}</span>
            </div>
          )}
          <p className="footer-text">{`Compare with ${type}`}</p>
        </div>
      )}
    </div>
  );
};

export default CardStatistical;
