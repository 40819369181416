/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CardUserInfo from "../../../components/card/CardUserInfo";
import { useToasts } from "react-toast-notifications";
import { Grid } from "@material-ui/core";
import FormAccountInfo from "../../../components/form/FormAccountInfo";
import { getAdminProfileApi } from "../../../services/apiList";
import { useDispatch } from "react-redux";
import { updateInfoAcc } from "../../../store/account/action";
import { loadingTogether } from "../../../../_metronic/_helpers";

const AccountInformation = () => {
  const [accountInfo, setAccountInfo] = useState(null);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const getAdminInfo = async () => {
    try {
      loadingTogether(true)
      const res = await getAdminProfileApi();
      if (res.error_code === 0) {
        setAccountInfo(res.data);
        dispatch(
          updateInfoAcc({
            avatar: res.data.avatar,
            avatar_thumb: res.data.avatar_thumb,
            preferred_name: res.data.preferred_first_name,
          })
        );
      }
    } catch (error: any) {
      addToast(error.message || error?.response?.statusText || error?.response?.data.message, { appearance: "error" });
    } finally{
      loadingTogether(false)
    }
  };

  useEffect(() => {
    getAdminInfo();
  }, []);

  return (
    <Grid container spacing={1} className="account-info-wrapper d-flex">
      <Grid item xs={5}>
        <CardUserInfo
          {...accountInfo}
          typeUser="0"
          addToast={addToast}
          getAdminInfo={getAdminInfo}
        />
      </Grid>
      <Grid item xs={7}>
        <FormAccountInfo
          accountInfo={accountInfo}
          addToast={addToast}
          getAdminInfo={getAdminInfo}
        />
      </Grid>
    </Grid>
  );
};

export default AccountInformation;
