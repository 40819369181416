/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import CardPatientInfo from "../../../components/card/CardPatientInfo";
import CardAppointHistory from "../../../components/card/CardAppointHistory";
import CardUserInfo from "../../../components/card/CardUserInfo";
import {
  getPatientProfileApi,
  managePatientApi,
} from "../../../services/apiList";
import { loadingTogether } from "../../../../_metronic/_helpers";
import ModalConfirm from "../../../components/modal/ModalConfirm";
import { PATIENT_STATUS_VALUES } from "../../../constant";

const PatientAccount = () => {
  const params: any = useParams();
  const { addToast } = useToasts();
  const [indexTab, setIndexTab] = useState(0);
  const [patientInfo, setPatientInfo] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const getPatientInfo = async () => {
    try {
      loadingTogether(true);
      const res = await getPatientProfileApi(params.patientId);
      if (res.error_code === 0) {
        setPatientInfo(res.data);
      }
    } catch (error: any) {
      addToast(
        error.message ||
          error?.response?.statusText ||
          error?.response?.data.message,
        { appearance: "success" }
      );
    } finally {
      loadingTogether(false);
    }
  };

  const handleMangePatient = async () => {
    try {
      const status_expect = patientInfo?.status === PATIENT_STATUS_VALUES.UNLOCK ? PATIENT_STATUS_VALUES.LOCK : PATIENT_STATUS_VALUES.UNLOCK;
      const fd: any = new FormData();
      fd.append("status", status_expect);
      const res = await managePatientApi(patientInfo?.id, fd);
      if (res.error_code === 0) {
        setShowModal(false);
        getPatientInfo();
        addToast(
          status_expect ===  PATIENT_STATUS_VALUES.LOCK
            ? "Lock patient successfully"
            : "Unlock patient successfully",
          { appearance: "success" }
        );
      }
    } catch (error: any) {
      setShowModal(false);
      addToast(
        error.message ||
          error?.response?.statusText ||
          error?.response?.data.message,
        { appearance: "error" }
      );
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getPatientInfo();
  }, []);

  return (
    <>
      <ModalConfirm
        show={showModal}
        onHide={handleClose}
        headerTitle={
          patientInfo?.status === 1
            ? "Do you want to lock this patient account?"
            : "Do you want to unlock this patient account?"
        }
        handleConfirm={handleMangePatient}
      />
      <Grid container spacing={1} className="account-info-wrapper d-flex">
        <Grid item xs={5}>
          <CardUserInfo
            typeUser="1"
            setIndexTab={setIndexTab}
            {...patientInfo}
          />
        </Grid>
        <Grid item xs={7}>
          {indexTab === 0 ? (
            <CardPatientInfo {...patientInfo} setShowModal={setShowModal} />
          ) : null}
          {indexTab === 1 ? (
            <CardAppointHistory
              listAppointment={patientInfo?.appointment_history ?? []}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default PatientAccount;
