/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CloseIcon from "@material-ui/icons/Close";
import paginationFactory from "react-bootstrap-table2-paginator";
import DoneIcon from "@material-ui/icons/Done";
import { Dropdown } from "react-bootstrap";
import ModalLevelPharmacist from "../modal/ModalLevelPharmacist";
import "../../asset/scss/table/table_moderation_pharmacist.scss";
import { useHistory } from "react-router-dom";
import {
  updateStatusPharmacistApi,
  updateLevelPharmacistApi,
} from "../../services/apiList";
import { useToasts } from "react-toast-notifications";
import utils from "../../utils";
import { MIN_EXPERIENCE_YEAR, PHARMACY_STATUS_VALUES } from "../../constant";
import ModalRejectPharmacist from "../modal/ModalRejectPharmacist";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const TablePharmacistModeration = ({
  getPharmacistModeration,
  dataFromApi,
  totalData,
  getDataPharmacist,
  setDataFromApi,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [currentUserAction, setCurrentUserAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [page, setPage] = useState(1);
  const [textSearch, setTextSearch] = useState("");
  const [sizePage, setSizePage] = useState(10);
  const [typeUpdate, setTypeUpdate] = useState(1);
  const [selectPharmacist, setSelectPharmacist] = useState([]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      style: {
        width: "5%",
        fontWeight: "500",
        fontSize: "15px",
      },
    },
    {
      dataField: "avatar",
      text: "Avatar",
      formatter: avatarFormatter,

      style: {
        width: "5%",
      },
    },
    {
      dataField: "full_name",
      text: "Full Name",
      sort: true,
      formatter: fullNameFormatter,
      style: {
        width: "20%",
        wordBreak: "break-word",
      },
    },
    {
      dataField: "phone_number",
      text: "Phone number",
      // sort: true,
      formatter: phoneNumberFormatter,
      style: {
        width: "15%",
        textAlign: "left",
      },
    },
    {
      dataField: "license_state",
      text: "Practicing certificate",
      // sort: true,
      formatter: certificateFormatter,
      style: {
        width: "20%",
        textAlign: "left",
      },
    },
    {
      dataField: "experience",
      text: "Experience",
      sort: true,
      formatter: experienceFormatter,
      style: {
        width: "10%",
        textAlign: "left",
      },
    },
    {
      dataField: "status",
      text: "Status",
      // sort: true,
      formatter: statusFormatter,
      style: {
        width: "15%",
        textAlign: "left",
      },
    },
    {
      dataField: "function",
      text: "Function",
      style: {
        width: "10%",
        textAlign: "left",
      },
      formatter: tagFormatter,
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
  ];

  function avatarFormatter(cell) {
    return (
      <div className="image-avatar">
        <img src={utils.getUrlImageServer(cell)} alt="avatar-user" />
      </div>
    );
  }

  function fullNameFormatter(cell, row) {
    return (
      <>
        <div className="preferred-name">{cell}</div>
        <div className="full-name">
          {row?.preferred_first_name ? `(${row?.preferred_first_name})` : ""}
        </div>
      </>
    );
  }

  function phoneNumberFormatter(cell, row) {
    return (
      <>
        <div>{utils.formatPhoneNumber(cell, row?.dial_code)}</div>
      </>
    );
  }

  function statusFormatter(cell, row) {
    const colorIcon = row?.status === 3 ? "#FADB14" : "#F95D5D";

    return (
      <>
        <FiberManualRecordIcon style={{ color: colorIcon, fontSize: 12 }} />
        <span className="ml-1">
          {row?.status === 3 ? "Waiting" : "Not accept"}
        </span>
      </>
    );
  }

  function experienceFormatter(cell, row) {
    const experience = row.pharmacist_information?.experience_year;
    return (
      <>
        <div>{experience ? `${experience} years` : "-"} </div>
      </>
    );
  }

  function certificateFormatter(cell, row) {
    return cell.length > 0 ? (
      <>
        <div>
          {cell.map((item, index) => (
            <div key={index}>
              {item.state.state_name} - {item.number}
            </div>
          ))}
        </div>
      </>
    ) : (
      <>
        <div>No</div>
      </>
    );
  }

  function tagFormatter(cell, row, rowIndex) {
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <button
              className="btn btn-more-action"
              onClick={() => {
                if (dataFromApi.length === 1) {
                  const heightTable = document.body.querySelector(
                    ".table-pharmacist-moderation"
                  ).scrollHeight;
                  setTimeout(() => {
                    document.body
                      .querySelector(".table-pharmacist-moderation")
                      .scrollTo(0, heightTable);
                  }, 200);
                }
              }}
            >
              <MoreHorizIcon style={{ color: "#000000", fontSize: 18 }} />
            </button>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              onClick={() => handleShowModal(2, row)}
            >
              <DoneIcon
                style={{
                  color: "#4ECB73",
                  fontSize: 14,
                  marginRight: "0.25rem",
                }}
              />
              Accept
            </Dropdown.Item>
            {row.status !== PHARMACY_STATUS_VALUES.NOT_ACCEPT && (
              <Dropdown.Item
                eventKey="2"
                className="d-flex align-items-center"
                onClick={() => {
                  handleShowModalReject(2, row);
                }}
              >
                <CloseIcon
                  style={{
                    color: "#F95D5D",
                    fontSize: 14,
                    marginRight: "0.25rem",
                  }}
                />
                Refuse
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  const NoDataIndication = () => {
    return <div className="text-nodata"> No record </div>;
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectPharmacist([...selectPharmacist, row.id]);
      return;
    }
    const updateSelect = selectPharmacist.filter((item) => item !== row.id);
    setSelectPharmacist(updateSelect);
  };

  const handleOnSelectAll = useCallback((isSelect, rows) => {
    if (isSelect) {
      setSelectPharmacist(rows.map((item) => item.id));
    } else {
      setSelectPharmacist([]);
    }
  }, []);

  const handleUpdateLevelPharmacist = async (type, callback) => {
    try {
      let listId;
      if (typeUpdate === 1) {
        listId = [...selectPharmacist];
      }
      if (typeUpdate === 2) {
        listId = [currentUserAction?.id];
      }
      if (type == "2") {
        let allProper = true;
        for (const pharmacist of dataFromApi) {
          if (listId.includes(pharmacist.id)) {
            if (pharmacist.pharmacist_information?.experience_year < MIN_EXPERIENCE_YEAR) {
              addToast("Pharmacist must have at least 10 years of experience", {
                appearance: "warning",
              });
              allProper = false;
              break;
            }
          }
        }
        if (!allProper) {
          return;
        }
      }

      const res = await updateLevelPharmacistApi(listId, type);
      if (res?.error_code === 0) {
        addToast("Success", { appearance: "success" });
        setPage(1);
        getDataPharmacist();
        setSelectPharmacist([]);
        return;
      }
    } catch (error) {
      addToast(error?.response?.statusText || error?.response?.data.message, {
        appearance: "error",
      });
    } finally {
      if (callback) {
        callback();
      }
    }
  };

  const handleManagePharmacist = (status_expect, idUser) => async (note) => {
    try {
      let listId;
      if (typeUpdate === 1) {
        listId = [...selectPharmacist];
      }
      if (typeUpdate === 2) {
        if (idUser) {
          listId = [idUser];
        } else {
          listId = [currentUserAction.id];
        }
      }
      const res = await updateStatusPharmacistApi(listId, status_expect, note);
      if (res.error_code === 0) {
        setSelectPharmacist([]);
        setCurrentUserAction(null);
        setPage(1);
        setSizePage(10);
        getDataPharmacist();
        addToast("Refuse pharmacist successfully", { appearance: "success" });
      }
    } catch (error) {
      addToast(
        error.message ||
          error?.response?.statusText ||
          error?.response?.data.message ||
          "Something wrong occurred",
        {
          appearance: "error",
        }
      );
    } finally {
      setShowModalReject(false);
    }
  };

  const handleChangeText = (e) => {
    setTextSearch(e?.target?.value);
  };

  const searchByKeyword = () => {
    setPage(1);
    getPharmacistModeration(1, sizePage, textSearch);
  };

  const handleShowModalReject = (type, user) => {
    if (user) {
      setCurrentUserAction(user);
    }
    setTypeUpdate(type);
    setShowModalReject(true);
  };

  const handleShowModal = (type, user) => {
    if (user) {
      setCurrentUserAction(user);
    }
    setTypeUpdate(type);
    setShowModal(true);
  };

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/pharmacist-info/${row?.id}`);
    },
  };

  const onTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePage(newState.sizePerPage);
    if (type === "pagination") {
      getPharmacistModeration(newState.page, newState.sizePerPage);
      return;
    }
    let result;
    if (newState.sortOrder === "asc") {
      result = newState.data.slice().sort((a, b) => {
        if (newState.sortField === "full_name") {
          return b[newState.sortField]?.localeCompare(a[newState.sortField]);
        }
        if (newState.sortField === "experience") {
          return (
            Number(b?.pharmacist_information?.experience_year ?? 0) -
            Number(a?.pharmacist_information?.experience_year ?? 0)
          );
        }
        if (a[newState.sortField] > b[newState.sortField]) {
          return 1;
        } else if (b[newState.sortField] > a[newState.sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = newState.data.slice().sort((a, b) => {
        if (newState.sortField === "full_name") {
          return a[newState.sortField]?.localeCompare(b[newState.sortField]);
        }
        if (newState.sortField === "experience") {
          return (
            Number(a?.pharmacist_information?.experience_year ?? 0) -
            Number(b?.pharmacist_information?.experience_year ?? 0)
          );
        }
        if (a[newState.sortField] > b[newState.sortField]) {
          return -1;
        } else if (b[newState.sortField] > a[newState.sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setDataFromApi(result);
  };

  useEffect(() => {
    getPharmacistModeration();
  }, []);

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectPharmacist,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  return (
    <>
      <ModalRejectPharmacist
        show={showModalReject}
        handleClose={() => setShowModalReject(false)}
        headerTitle="State your reasons for refusing this pharmacist"
        handleRejectPharmacist={(note) =>
          handleManagePharmacist(PHARMACY_STATUS_VALUES.NOT_ACCEPT)(note)
        }
      />
      <ModalLevelPharmacist
        show={showModal}
        handleClose={() => setShowModal(false)}
        headerTitle="Level setting for this pharmacist !"
        handleUpdateLevelPharmacist={handleUpdateLevelPharmacist}
      />
      <div className="table-moderation-pharmacist">
        <div className="body-moderation-pharmacist data-table">
          <div className="d-flex justify-content-between align-items-center pb-1 header-table">
            <div className="wrapper-input-search">
              <div className="position-relative input-container">
                <input
                  type="text"
                  name="searchText"
                  placeholder="Search for account"
                  value={textSearch}
                  onChange={handleChangeText}
                  autoComplete="off"
                />
                <i className="flaticon-search icon-md icon-search" />
              </div>
              <div className="wrapper-search">
                <button
                  className="btn btn-primary btn-search"
                  onClick={searchByKeyword}
                >
                  Search
                </button>
              </div>
            </div>
            {selectPharmacist.length >= 1 && (
              <div className="wrapper-actions-table">
                <div className="num-select">
                  {`Selected ${selectPharmacist.length} items`}
                </div>
                <button
                  className="btn btn-action btn-accept"
                  onClick={() => handleShowModal(1)}
                >
                  Accept
                </button>
                <button
                  className="btn btn-action btn-refuse"
                  onClick={() => handleShowModalReject(1)}
                >
                  Refuse
                </button>
              </div>
            )}
          </div>
          <BootstrapTable
            wrapperClasses="table-responsive table-pharmacist-moderation"
            key={1}
            keyField="id"
            remote={{
              filter: true,
              pagination: true,
              sort: false,
              cellEdit: false,
            }}
            onTableChange={onTableChange}
            page={page}
            sizePerPage={sizePage}
            data={dataFromApi}
            columns={columns}
            rowEvents={rowEvents}
            pagination={paginationFactory({
              totalSize: totalData,
              page: page,
              sizePerPage: sizePage,
            })}
            selectRow={selectRow}
            noDataIndication={() => <NoDataIndication />}
          />
        </div>
      </div>
    </>
  );
};

export default TablePharmacistModeration;
