import React, { useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { COLOR_USER_STATUS } from "../../constant";
import utils from "../../utils";
import { activePharmacist } from "../../constant";
import "../../asset/scss/dialog/dialog_pharmacist_info.scss";
import ModalConfirm from "../modal/ModalConfirm";

const DialogPharmacistInfo = ({
  pharmacistInfo,
  handleShowModal,
  typeUser = "0",
  handleManagePharmacist,
  handleShowModalReject,
}: any) => {
  const [show, setShow] = useState(false);
  let colorIcon = COLOR_USER_STATUS[pharmacistInfo?.status]?.color;

  return (
    <>
      <ModalConfirm
        show={show}
        onHide={() => setShow(false)}
        headerTitle={
          pharmacistInfo?.status === 1
            ? "Do you want to lock this pharmacist account?"
            : "Do you want to unlock this pharmacist account?"
        }
        handleConfirm={() =>
          handleManagePharmacist(pharmacistInfo, () => setShow(false))
        }
      />
      <div className="wrapper-dialog bg-white">
        <div className="header-dialog">
          <div className="title-header">Account information</div>
          {typeUser === "1" &&
            activePharmacist.includes(pharmacistInfo?.status) && (
              <button
                className="btn btn-primary btn-actions-user"
                onClick={() => setShow(true)}
              >
                {pharmacistInfo?.status === 1 ? <LockOpenIcon /> : <LockIcon />}
              </button>
            )}
          {typeUser === "0" &&
            pharmacistInfo?.status === 3 && (
              <div className="wrapper-actions-dialog">
                <button
                  className=" btn btn-action btn-accept"
                  onClick={handleShowModal}
                >
                  Accept
                </button>
                <button
                  className="btn btn-action btn-refuse"
                  onClick={handleShowModalReject}
                >
                  Refuse
                </button>
              </div>
            )}
            {typeUser === "0" &&
            pharmacistInfo?.status === 4 && (
              <div className="wrapper-actions-dialog">
                <button
                  className=" btn btn-action btn-accept"
                  onClick={handleShowModal}
                >
                  Accept
                </button>
              </div>
            )}
        </div>
        <div className="content-dialog">
          <table>
            <tbody>
              <tr>
                <td className="info-text">Preferred first name:</td>
                <td className="detail-info">
                  {pharmacistInfo?.preferred_first_name || "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">First and last name:</td>
                <td className="detail-info">
                  {utils.formatFullName(
                    pharmacistInfo?.first_name,
                    pharmacistInfo?.last_name
                  )}
                </td>
              </tr>
              <tr>
                <td className="info-text">Phone number:</td>
                <td className="detail-info">
                  {utils.formatPhoneNumber(
                    pharmacistInfo?.phone_number,
                    pharmacistInfo?.dial_code
                  )}
                </td>
              </tr>
              <tr>
                <td className="info-text">Practicing certificate:</td>
                <td className="detail-info">
                  {pharmacistInfo?.license_state.length === 0 && "No"}
                  {pharmacistInfo?.license_state?.map(
                    (item: any, index: number) => (
                      <span key={index}>
                        {item.state.state_name} - {item.number}
                        {index + 1 !== pharmacistInfo?.license_state?.length
                          ? ", "
                          : ""}
                      </span>
                    )
                  )}
                </td>
              </tr>

              <tr>
                <td className="info-text">Experience:</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information?.experience_year
                    ? `${pharmacistInfo?.pharmacist_information?.experience_year} years`
                    : "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Level:</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information?.level === 1
                    ? "Regular Pharmacist"
                    : "Senior Pharmacist"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Age:</td>
                <td className="detail-info">{pharmacistInfo?.age}</td>
              </tr>
              <tr>
                <td className="info-text">Gender:</td>
                <td className="detail-info">
                  {pharmacistInfo?.gender === 1 ? "Male" : "Female"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Address:</td>
                <td className="detail-info">
                  {`${pharmacistInfo?.address_line_1 ||
                    pharmacistInfo?.address_line_2 ||
                    ""}`}
                </td>
              </tr>
              <tr>
                <td className="info-text">Consulting fee:</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information?.consulting_fee
                    ? `${pharmacistInfo?.pharmacist_information?.consulting_fee} usd/hour`
                    : "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Number of counseling sessions:</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information?.counseling_sessions
                    ? `${pharmacistInfo?.pharmacist_information?.counseling_sessions} day`
                    : "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Board certification:</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information
                    ?.board_certification ?? "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Additional education/training:</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information
                    ?.additional_education ?? "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Employer(s):</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information?.employer ?? "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Patient care philosophy:</td>
                <td className="detail-info">
                  {pharmacistInfo?.pharmacist_information
                    ?.patient_care_philosophy ?? "No"}
                </td>
              </tr>
              <tr>
                <td className="info-text">Status:</td>
                <td className="detail-info">
                  <FiberManualRecordIcon
                    style={{ color: colorIcon, fontSize: 12 }}
                  />
                  <span className="ml-1">
                    {COLOR_USER_STATUS?.[pharmacistInfo?.status]?.title}
                  </span>
                </td>
              </tr>
              { pharmacistInfo?.status === 4 && <tr>
                <td className="info-text">Reason of rejection:</td>
                <td className="detail-info">
                  <span className="ml-1">
                    {pharmacistInfo?.pharmacist_information?.note_to_pharmacist}
                  </span>
                </td>
              </tr>}
            </tbody>
          </table>
          <div className="container-attachment">
            <div className="title-attach">
              Attachment<span className="text-import">*</span>
            </div>
            <table>
              <tbody>
                <tr>
                  <td className="info-text">File Attach:</td>
                  <td className="detail-info">
                    {pharmacistInfo?.pharmacist_information?.certification_file
                      .length > 0 ? (
                      <>
                        {pharmacistInfo?.pharmacist_information?.certification_file.map(
                          (item: any, index: number) => (
                            <div className="item-attach" key={index}>
                              <a href={item} target="_blank">
                                {utils.getFileNameFromUrl(item)}
                              </a>
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      "No"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="info-text">Image Attach:</td>
                  <td className="detail-info">
                    {pharmacistInfo?.pharmacist_information?.certification_image
                      .length > 0 ? (
                      <>
                        {pharmacistInfo?.pharmacist_information?.certification_image.map(
                          (item: any, index: number) => (
                            <div className="item-attach" key={index}>
                              <a
                                href={utils.getFullUrlImage(item)}
                                target="_blank"
                              >
                                {utils.getFileNameFromUrl(item)}
                              </a>
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      "No"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DialogPharmacistInfo;
