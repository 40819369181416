import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  ResetPasswordStepOneSchema,
  ResetPasswordStepTwoSchema,
} from "../../../plugins/validate";
import { forgotPasswordApi, resetPasswordApi } from "../../../services/apiList";

interface IInitialValue {
  email?: string;
  opt_code?: string;
  new_password?: string;
  confirm_password?: string;
}

const initialValues: IInitialValue = {
  email: "",
  opt_code: "",
  new_password: "",
  confirm_password: "",
};

const ForgotPassword = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [emailRegister, setEmailRegister] = useState<string | null>(null);

  const handleSubmit = async (values: IInitialValue) => {
    try {
      setIsLoading(true);
      if (step === 0) {
        const res = await forgotPasswordApi(values.email ?? "");
        if (res.error_code === 0) {
          setEmailRegister(values.email || "");
          setStep(1);
        }
      }
      if (step === 1) {
        const res = await resetPasswordApi({
          code: values.opt_code || "",
          new_password: values.new_password || "",
          confirm_new_password: values.confirm_password || "",
        });
        if (res.error_code === 0) {
          addToast("Change password successfully", { appearance: "success" });
          history.push("/auth/login");
        }
      }
    } catch (error: any) {
      addToast(
        error?.response?.data.message ||
          error.message ||
          error?.response?.statusText,
        {
          appearance: "error",
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const chooseSchemeValidate = () => {
    switch (step) {
      case 0:
        return ResetPasswordStepOneSchema;
      case 1:
        return ResetPasswordStepTwoSchema;
    }
  };

  return (
    <div className="container-reset-pass login-form login-signin">
      <div className="text-center mb-10 mb-lg-20 container-title">
        <img
          alt="Logo"
          className="footer-background"
          src={toAbsoluteUrl("/media/svg/background/bg-12.svg")}
        />
        <p className="text-center mt-5 h1 name-company">IMPACTPHARM</p>
      </div>

      <Formik
        key={step}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={chooseSchemeValidate()}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, getFieldProps }) => (
          <>
            <div className="container-title-step">
              <p className="reset-title">Reset Password</p>
              {step === 0 && (
                <p className="reset-subtitle">
                  Please enter your registered email.
                </p>
              )}
              {step === 1 && (
                <p className="reset-subtitle">
                  If an account exists for {emailRegister}, you will get an
                  email with code to reset password.
                </p>
              )}
            </div>
            <Form className="form fv-plugins-bootstrap" autoComplete="new-password">
                {step === 0 && (
                  <div className="form-group fv-plugins-icon-container">
                    <div className="container-input">
                      <img
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Auth/username.svg"
                        )}
                        alt=""
                        className="icon-input"
                      />
                      <TextField
                        id="email_forgot"
                        placeholder="Email"
                        className="mt-4 text-input"
                        variant="outlined"
                        error={!errors?.email || !touched.email ? false : true}
                        {...getFieldProps("email")}
                      />
                    </div>
                    {touched.email && errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.email}</div>
                      </div>
                    ) : (
                      <div className="fv-plugins-message-container" />
                    )}
                  </div>
                )}
                {step === 1 && (
                  <div>
                    <div className="form-group fv-plugins-icon-container">
                      <div className="container-input">
                        <img
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Auth/verification-code.svg"
                          )}
                          alt=""
                          className="icon-input"
                        />
                        <TextField
                          id="opt_code"
                          placeholder="Verification codes"
                          className="mt-4 text-input opt_code"
                          variant="outlined"
                          type="text"
                          error={
                            !errors.opt_code || !touched.opt_code ? false : true
                          }
                          {...getFieldProps(`opt_code`)}

                        />
                      </div>
                      {touched.opt_code && errors.opt_code ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.opt_code}</div>
                        </div>
                      ) : (
                        <div className="fv-plugins-message-container" />
                      )}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <div className="container-input">
                        <img
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Auth/password.svg"
                          )}
                          alt=""
                          className="icon-input"
                        />
                        <TextField
                          id="new_password"
                          placeholder="New password"
                          className="mt-4 text-input"
                          type="password"
                          variant="outlined"
                          error={
                            !errors.new_password || !touched.new_password
                              ? false
                              : true
                          }
                          {...getFieldProps(`new_password`)}
                          autoComplete="new-password"
                        />
                      </div>
                      {touched.new_password && errors.new_password ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.new_password}
                          </div>
                        </div>
                      ) : (
                        <div className="fv-plugins-message-container" />
                      )}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <div className="container-input">
                        <img
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Auth/password.svg"
                          )}
                          alt=""
                          className="icon-input"
                        />
                        <TextField
                          id="confirm_password"
                          placeholder="Confirm new password"
                          className="mt-4 text-input"
                          variant="outlined"
                          type="password"
                          error={
                            !errors.confirm_password ||
                            !touched.confirm_password
                              ? false
                              : true
                          }
                          {...getFieldProps(`confirm_password`)}
                        />
                      </div>
                      {touched.confirm_password && errors.confirm_password ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.confirm_password}
                          </div>
                        </div>
                      ) : (
                        <div className="fv-plugins-message-container" />
                      )}
                    </div>
                  </div>
                )}
                <div className="form-group d-flex flex-wrap justify-content-start align-items-center container-reset">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3 ${isLoading &&
                      "btn-disable"}`}
                    disabled={isLoading}
                  >
                    <span className="font-size-lg font-weight-bolder">
                      {step === 0 ? "Send Request" : "Confirm"}
                    </span>
                    {isLoading && (
                      <span className="ml-3 spinner spinner-white" />
                    )}
                  </button>
                </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
