import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Layout } from "../../_metronic/layout";
import BasePage from "../layout/BasePage";
import { AuthPage } from "../container/Auth";
import ErrorsPage from "../container/NotFound/ErrorsPage";
import utils from "../utils";

export function Routes() {
  const location = useLocation();
  return (
    <Switch>
      {!utils.checkAuth(location) ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth/login" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />

      {!utils.checkAuth(location) ? (
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
