/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import SVG from "react-inlinesvg";
import {  toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useDropzone } from "react-dropzone";
import "../../asset/scss/card_user_info.scss";
import { uploadUserImage } from "../../services/apiList";
import utils from "../../utils";

const CardUserInfo = ({
  preferred_first_name,
  typeUser = "0",
  setIndexTab,
  avatar,
  appointment_total,
  addToast,
  getAdminInfo,
  total_appointment,
  appointment_rate,
  turnover,
  last_name,
  first_name,
}: any) => {

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ["image/jpeg", "image/png", "image/jpg"],
    onDrop: (files: any) => handleFile(files),
    maxSize: 1024000,
    onDropRejected: (files: any) => {
      addToast("File size is too large", { appearance: "error" });
    }
  });

  const handleChangeAvatar = () => {
    open();
  };

  const handleFile = async (files: any) => {
    try {
      if(files.length > 0){
        const fd = new FormData();
        fd.append("image_type", "1");
        fd.append("image", files[0]);
        const res = await uploadUserImage(fd);
        if (res.error_code === 0) {
          addToast("Profile photo update successfully", {
            appearance: "success",
          });
          getAdminInfo();
        }
      }
    } catch (error:any) {
      addToast(error.message || error?.response?.statusText || error?.response?.data.message, { appearance: "error" });
    }
  };

  return (
    <div className="card-user-info">
      <div className="box-color">
        <div className={`info-container ${typeUser === "1" && "no-click"}`}>
          <div
            className="container-avatar"
            onClick={typeUser === "0" ? () => handleChangeAvatar() : () => {}}
          >
            <img
              src={utils.getUrlImageServer(avatar)}
              alt="avatar-user"
              className="avatar-user"
            />
            {typeUser === "0" && (
              <div className="overlay-avatar">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Camera.svg")}
                />
              </div>
            )}
            {typeUser === "0" && (
              <div {...getRootProps()} className="d-none">
                <input
                  {...getInputProps({
                    onChange: handleFile,
                  })}
                  className="d-none"
                />
              </div>
            )}
          </div>
          <span className="username">{preferred_first_name || utils.formatFullName(first_name, last_name)}</span>
        </div>
      </div>
      {typeUser === "0" && (
        <div className="card-footer-info">
          <div className="box-info">
            <div className="subtitle-box">Turnover</div>
            <span>{turnover} $</span>
          </div>
          <div className="box-info">
            <div className="subtitle-box">Appointment</div>
            <span>{appointment_total}</span>
          </div>
        </div>
      )}
      {typeUser === "1" && (
        <div className="card-footer-info">
          <div className="box-info has-select" onClick={() => setIndexTab(1)}>
            <div className="subtitle-box">Total appointment</div>
            <span>{total_appointment}</span>
          </div>
          <div className="box-info">
            <div className="subtitle-box">Appointment rate</div>
            <span>{appointment_rate}%</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardUserInfo;
