import React from "react";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@material-ui/icons/StarHalf";

interface IProps {
  rating: number;
}

const Rating = ({ rating = 1 }: IProps) => {
  const listStar = [1, 2, 3, 4, 5];
  return (
    <div className="container-list-start">
      {listStar.map((value, index) => {
        if (value < rating) {
          return (
            <StarIcon style={{ color: "#F6D060", fontSize: 18 }} key={index} />
          );
        }
        if (rating === value) {
          return (
            <StarIcon style={{ color: "#F6D060", fontSize: 18 }} key={index} />
          );
        }
        if (Math.round(rating) === value && rating < value) {
          return (
            <StarHalfIcon
              style={{ color: "#F6D060", fontSize: 18 }}
              key={index}
            />
          );
        }
        if (Math.round(rating) < value) {
          return (
            <StarIcon style={{ color: "#E2E5EA", fontSize: 18 }} key={index} />
          );
        }
        return (
          <StarIcon style={{ color: "#E2E5EA", fontSize: 18 }} key={index} />
        );
      })}
    </div>
  );
};

export default Rating;
