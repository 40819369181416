export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export function loadingTogether(together) {
  const elementLoad = document.getElementById("loading-together");
  const root = document.getElementById("root");
  const splashScreen = document.getElementById("splash-screen");
  if (together && splashScreen.classList.contains("hidden")) {
    elementLoad.style.height = root.scrollHeight + "px";
    elementLoad.classList.remove("hide-loading");
  } else {
    elementLoad.style.height = root.offsetHeight + "px";
    elementLoad.classList.add("hide-loading");
  }
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;
