import React from "react";
import TableAppointmentManage from "../../../components/table/TableAppointmentManage";

const AppointmentManage = () => {
  return (
    <div className="pharmacist-manage-wrapper">
      <div className="pharmacist-moderation-container bg-white">
        <div className="title-container">Appointment management</div>
        <TableAppointmentManage />
      </div>
    </div>
  );
};

export default AppointmentManage;
