/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { useHistory } from "react-router-dom";
import "../../asset/scss/table/table_appointment_manage.scss";
import { useToasts } from "react-toast-notifications";
import { getAppointmentManageApi } from "../../services/apiList";
import { loadingTogether } from "../../../_metronic/_helpers";
import { COLOR_APPOINTMENT_STATUS, listButtonFilter } from "../../constant";
import usePrevious from "../../hook/usePrevious";
import utils from "../../utils";

const TableAppointmentManage = () => {
  const history = useHistory();
  const [dataFromApi, setDataFromApi] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [typeAppointment, setTypeAppointment] = useState(0);
  const prevType = usePrevious(typeAppointment ?? 0);
  const { addToast } = useToasts();

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return b > a;
        }
        return a < b;
      },
      style: {
        width: "5%",
        fontWeight: "500",
        fontSize: "15px",
      },
    },
    {
      dataField: "partner",
      text: "Partner",
      formatter: partnerFormatter,
      style: {
        width: "15%",
        textAlign: "center",
      },
    },
    {
      dataField: "appointment",
      text: "Appointment information",
      formatter: appointmentFormatter,
      style: {
        width: "40%",
      },
    },
    {
      dataField: "appointment_status",
      text: "Status",
      // sort: true,
      formatter: statusFormatter,
      style: {
        width: "15%",
        textAlign: "left",
      },
    },
  ];

  function partnerFormatter(cell, row) {
    return (
      <div className="partner-wrapper">
        <div className="info-partner">
          <img
            src={utils.getUrlImageServer(row.pharmacy.avatar_thumb)}
            alt="avatar-user"
            className="img-user"
          />
          <div className="name-user">
            {row.pharmacy?.preferred_first_name ||
              utils.formatFullName(
                row.pharmacy?.first_name,
                row.pharmacy?.last_name
              )}
          </div>
        </div>
        <SwapHorizIcon
          style={{ color: "#8C8C8C", fontSize: 30, marginBottom: "2rem" }}
        />
        <div className="info-partner">
          <img
            src={utils.getUrlImageServer(row.patient?.avatar_thumb)}
            alt="avatar-user"
          />
          <div className="name-user">
            {row.patient?.preferred_first_name ||
              utils.formatFullName(
                row.patient?.first_name,
                row.patient?.last_name
              )}
          </div>
        </div>
      </div>
    );
  }

  function appointmentFormatter(cell, row) {
    return (
      <div className="appoint-info-wrapper">
        <div className="title-container">
          <div className="title-info">Pharmacist: </div>
          <div className="title-info">Patient: </div>
          <div className="title-info">Appointment time:</div>
          <div className="title-info">Appointed time:</div>
          <div className="title-info">Consulting fee:</div>
        </div>
        <div className="detail-container">
          <div className="detail-info username">
            {row.pharmacy?.preferred_first_name ||
              utils.formatFullName(
                row.pharmacy?.first_name,
                row.pharmacy?.last_name
              )}
          </div>
          <div className="detail-info username">
            {row.patient?.preferred_first_name ||
              utils.formatFullName(
                row.patient?.first_name,
                row.patient?.last_name
              )}
          </div>
          <div className="detail-info">
            {row?.appointment_date
              ? utils.formatDate(row?.appointment_date)
              : "No"}
          </div>
          <div className="detail-info">
            {row?.appointment_date
              ? utils.formatDate(row?.appointment_date)
              : "No"}
          </div>
          <div className="detail-info">{row?.consulting_fee} $/hour</div>
        </div>
      </div>
    );
  }

  const onChangeTypeAppointment = (type) => {
    setTypeAppointment(type);
  };

  function statusFormatter(cell, row) {
    let colorIcon = COLOR_APPOINTMENT_STATUS[cell]?.color;
    let statusStr = COLOR_APPOINTMENT_STATUS?.[cell]?.title;

    return (
      <div className="d-flex justify-content-start align-items-center">
        <FiberManualRecordIcon style={{ color: colorIcon, fontSize: 12 }} />
        <span className="ml-1 text-black">{statusStr}</span>
      </div>
    );
  }

  const NoDataIndication = () => {
    return <div className="text-nodata">No record</div>;
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/appointment-info/${row?.id}`);
    },
  };

  const getListAppointmentManage = async (pageNum, pageSize) => {
    try {
      loadingTogether(true);
      const params = {
        search: textSearch,
        page: pageNum ?? page,
        page_size: pageSize ?? sizePage,
        appointment_status: typeAppointment,
      };
      const res = await getAppointmentManageApi(params);
      setDataFromApi(res.data.results);
      setTotalData(res.data.count);
    } catch (error) {
      addToast(error.message || error?.response?.statusText || error?.response?.data.message, { appearance: "error" });
    } finally {
      loadingTogether(false);
    }
  };

  const onTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePage(newState.sizePerPage);
    if(type === "pagination"){
      getListAppointmentManage(newState.page, newState.sizePerPage)
      return;
    }
    let result;
    if (newState.sortOrder === "asc") {
      result = newState.data.slice().sort((a, b) => {
        if (a[newState.sortField] > b[newState.sortField]) {
          return 1;
        } else if (b[newState.sortField] > a[newState.sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = newState.data.slice().sort((a, b) => {
        if (a[newState.sortField] > b[newState.sortField]) {
          return -1;
        } else if (b[newState.sortField] > a[newState.sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setDataFromApi(result);
  };

  const handleChangeText = (e) => {
    setTextSearch(e?.target?.value);
  };

  const searchByKeyword = () => {
    setPage(1)
    getListAppointmentManage(1);
  };

  useEffect(() => {
    getListAppointmentManage();
  }, []);

  useEffect(() => {
    let pageParams;
    if (typeAppointment !== prevType) {
      pageParams = 1;
      setPage(pageParams);
    }
    getListAppointmentManage(pageParams);
  }, [typeAppointment]);

  return (
    <>
      <div className="table-manage-appointment">
        <div className="body-manage-appointment data-table">
          <div className="d-flex justify-content-between align-items-center pb-1 header-table">
            <div className="wrapper-input-search">
              <div className="position-relative input-container">
                <input
                  type="text"
                  name="searchText"
                  placeholder="Search for account"
                  value={textSearch}
                  onChange={handleChangeText}
                  autoComplete="off"
                />
                <i className="flaticon-search icon-md icon-search" />
              </div>
              <div className="wrapper-search">
                <button
                  className="btn btn-primary btn-search"
                  onClick={searchByKeyword}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="wrapper-actions-table">
              {listButtonFilter.map((item, index) => {
                return (
                  <button
                    className={`btn-filter ${typeAppointment === index &&
                      "btn-active"}`}
                    onClick={() => onChangeTypeAppointment(index)}
                    key={index}
                  >
                    {index !== 0 && (
                      <FiberManualRecordIcon
                        style={{ color: item.color, fontSize: 12 }}
                      />
                    )}
                    <span className="ml-1">{item.title}</span>
                  </button>
                );
              })}
            </div>
          </div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            key="appointment_1"
            keyField="id"
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: false,
            }}
            page={page}
            sizePerPage={sizePage}
            data={dataFromApi}
            columns={columns}
            rowEvents={rowEvents}
            pagination={paginationFactory({
              totalSize: totalData,
              page: page,
              sizePerPage: sizePage,
            })}
            noDataIndication={() => <NoDataIndication />}
            onTableChange={onTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default TableAppointmentManage;
