/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useToasts } from "react-toast-notifications";
import { CardStatistical } from "../../../components/card";
import StackColumnChart from "../../../components/chart/StackColumnChart";
import {
  getOverviewStatisticalApi,
  getStatisticalAdminApi,
} from "../../../services/apiList";
import moment from "moment";
import "../styles/statistical-management.scss";
import utils from "../../../utils";
import { loadingTogether } from "../../../../_metronic/_helpers";
import { IDataChartColumn, ISeriesChartColumn } from "../../../services/types";
import { initDataChartColumn } from "../../../constant";

const LIST_TAB_ITEM = [
  { title: "User Login", key: 0 },
  { title: "Appointment", key: 1 },
  { title: "Rating", key: 2 },
];

const PARAM_TAB: any = {
  0: 'user-stats',
  1: 'appointment-stats',
  2: 'ratting-star',
}

const typeContent: any = {
  overview: null,
  data_this_month: "last month",
  data_this_day: "yesterday",
};

const StatisticalManagement = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [typeFilter, setTypeFilter] = useState("0");
  const [dateRange, setDateRange] = useState([
    new Date(
      moment()
        .subtract(1, "month")
        .format()
    ),
    new Date(moment().format())
  ]);
  const [startDate, endDate] = dateRange;
  const [dataOverview, setDataOverview] = useState<any[]>([]);
  const [stateChartColumn, setStateChartColumn] = useState<IDataChartColumn>(
    initDataChartColumn
  );
  const { addToast } = useToasts();

  const handleChange = (indexItem: any) => {
    setActiveIndex(Number(indexItem));
  };

  const mapValueArray = (dataVal: any, keyFilter: string) => {
    return dataVal.map((item: any) => item[keyFilter]);
  };

  const getOverviewsStatistics = async () => {
    try {
      const res = await getOverviewStatisticalApi();
      return res;
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatDataChart = (data: any) => {
    const dataSeriesChartColumn: ISeriesChartColumn[] = [];
    const dataCategoriesChartColum: string[] = mapValueArray(data, "date");
    let arrKeyMapData: any[] = [];
    if (activeIndex === 0) {
      arrKeyMapData = [
        { keyName: "patient_user", title: "Patient account" },
        { keyName: "pharmacy_user", title: "Pharmacist account" },
      ];
    }
    if (activeIndex === 1) {
      arrKeyMapData = [{ keyName: "total_appointment", title: "Appointment" }];
    }
    if (activeIndex === 2) {
      arrKeyMapData = [
        { keyName: "total_appointment", title: "Appointment" },
        { keyName: "total_star", title: "Ratting" },
      ];
    }
    arrKeyMapData.forEach((item: any) => {
      const { keyName, title } = item;
      dataSeriesChartColumn.push({
        name: title,
        data: mapValueArray(data, keyName),
      });
    });
    setStateChartColumn({
      ...stateChartColumn,
      dataLabels: dataCategoriesChartColum,
      dataSeries: dataSeriesChartColumn,
    });
  };

  const formatOverviewData = (data: any) => {
    const dataOverview = utils.formatDataOverviewAnalytic(data);
    setDataOverview(dataOverview);
  };

  const getStatisticManagement = async () => {
    try {
      const typeChart = PARAM_TAB[activeIndex];
      const res = await getStatisticalAdminApi(typeChart, {
        type_stats: typeFilter,
        start_date: moment(startDate).format("DD-MM-YYYY"),
        end_date: moment(endDate).format("DD-MM-YYYY"),
      });
      return res;
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchInitData = async () => {
    try {
      loadingTogether(true);
      const arrResponse = await Promise.all([
        getOverviewsStatistics(),
        getStatisticManagement(),
      ]);
      formatOverviewData(arrResponse[0].data);
      formatDataChart(arrResponse[1].data);
    } catch (error: any) {
      addToast(error.message || error?.response?.statusText || error?.response?.data.message, { appearance: "error" });
    } finally {
      loadingTogether(false);
    }
  };

  const handleApplyFilterStatistics = async () => {
    try {
      loadingTogether(true)
      const res = await getStatisticManagement()
      formatDataChart(res.data);
    } catch (error: any) {
      addToast(error.message || error?.response?.statusText || error?.response?.data.message, { appearance: "error" });
    } finally {
      loadingTogether(false)
    }
  };

  const handleChangeDate = () => {
    if (!endDate) {
      setDateRange([
        startDate,
        startDate
      ]);
      return;
    }
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  useEffect(() => {
    handleApplyFilterStatistics();
  }, [activeIndex]);

  return (
    <div className="statistical-container bg-white px-6 py-6">
      <div className="sub-header mb-4">
        <span>Statistical Management</span>
      </div>
      <div className="statistical-content">
        {dataOverview.length > 0 &&
          dataOverview.map((itemData: any, index: number) => (
            <div className="list-card-container" key={index}>
              <p className="title-list-card mb-2">{itemData.title}</p>
              <div className="content-list-card d-flex flex-row justify-content-start align-items-center">
                {itemData.value.map((item: any, index: number) => (
                  <CardStatistical
                    key={index}
                    typeContent={item.typeContent}
                    iconName={item.iconName}
                    titleHeader={item.titleHeader}
                    dataItem={item}
                    type={typeContent[itemData.key]}
                    hasUser={item.hasUser}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
      <div className="line-break"></div>
      <div className="chart-container">
        <div className="sub-header mb-4 mt-4">
          <span>Statistical Management</span>
        </div>
        <div className="filter-chart d-flex flex-row align-items-end mb-5">
          <div className="choose-time">
            <div className="title-choose">Choose time</div>
            <div className="container-datepicker">
              <i className="flaticon2-calendar-9 icon-lg icon-calendar" />
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(date: any) => {
                  setDateRange(date);
                }}
                onCalendarClose={() => {
                  handleChangeDate();
                }}
                className="py-2"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div className="select-wrapper">
            <div className="title-select">Statistical Type</div>
            <select
              name="select-filter"
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              className="select-filter"
            >
              <option value="0">By date</option>
              <option value="1">By weekly</option>
              <option value="2">By month</option>
            </select>
          </div>
          <button
            className="btn btn-primary btn-filter"
            onClick={handleApplyFilterStatistics}
          >
            Apply
          </button>
        </div>
        <div className="tab-chart">
          <Tabs
            id="controlled-tab-example"
            activeKey={activeIndex}
            onSelect={handleChange}
            className="mb-3"
          >
            {LIST_TAB_ITEM.map((item) => (
              <Tab eventKey={item.key} title={item.title} key={item.key} />
            ))}
          </Tabs>
        </div>
        <div className="container-chart">
          <StackColumnChart
            dataSeries={stateChartColumn.dataSeries}
            dataLabels={stateChartColumn.dataLabels}
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticalManagement;
