import React, { useState } from 'react'
import '../../asset/scss/form_account_info.scss'
import { useFormik } from 'formik'
import { AccountInfoSchema } from '../../plugins/validate'
import { updateProfileAdminApi } from '../../services/apiList'

const FormAccountInfo = ({ accountInfo, addToast, getAdminInfo }: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const initialValues = {
    username: accountInfo?.preferred_first_name || '',
    account_type: 'Admin',
    email: accountInfo?.email || '',
    phone_number: accountInfo?.phone_number ? `${accountInfo?.phone_number}` : '',
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AccountInfoSchema,
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const res = await updateProfileAdminApi({
          userId: accountInfo.id,
          phone_number: values.phone_number,
          preferred_first_name: values.username,
        })
        if (res.error_code === 0) {
          addToast('Successfully updated', { appearance: 'success' })
          getAdminInfo()
        }
      } catch (error: any) {
        addToast(error.message || error?.response?.statusText || error?.response?.data.message, {
          appearance: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <div className="form-account-info bg-white">
      <div className="header-form">Account information</div>
      <div className="body-form">
        <div className="form-group">
          <div className="label-input-form">
            User Name <span className="required-field">*</span>
          </div>
          <input
            type="text"
            className={`input-form-group${formik.errors.username ? ' error-input' : ''}`}
            {...formik.getFieldProps('username')}
          />
          <div className="message-err-input">
            {formik.touched.username && formik.errors.username && (
              <span className="title-invalid">{formik.errors.username}</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="label-input-form">
            Account Type <span className="required-field">*</span>
          </div>
          <input
            type="text"
            className="input-form-group input-disable"
            value={formik.getFieldProps('account_type').value}
            disabled
          />
          <div className="message-err-input"></div>
        </div>
        <div className="form-group">
          <div className="label-input-form">
            Email <span className="required-field">*</span>
          </div>
          <input
            type="text"
            className="input-form-group input-disable"
            value={formik.getFieldProps('email').value}
            disabled
          />
          <div className="message-err-input"></div>
        </div>
        <div className="form-group">
          <div className="label-input-form">
            Phone number <span className="required-field">*</span>
          </div>
          <input
            type="text"
            className={`input-form-group${formik.errors.phone_number ? ' error-input' : ''}`}
            {...formik.getFieldProps('phone_number')}
          />
          <div className="message-err-input">
            {formik.touched.phone_number && formik.errors.phone_number && (
              <span className="title-invalid">{formik.errors.phone_number}</span>
            )}
          </div>
        </div>
      </div>
      <div className="footer-form">
        <button
          className={`btn btn-primary btn-save ${isLoading && 'btn-disable'}`}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default FormAccountInfo
