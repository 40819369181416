import React, { Suspense, createRef } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { Routes } from "./router";
import { configureStore } from "./store/store";

const { PUBLIC_URL } = process.env;

export const routerRef = createRef<any>();

const { store, persistor } = configureStore();

function App() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={PUBLIC_URL} ref={routerRef}>
        <MaterialThemeProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Routes />
            </PersistGate>
          </Provider>
        </MaterialThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
