import React from "react";
import { Button, Modal } from "react-bootstrap";

interface IProps {
  show: boolean;
  onHide: () => void;
  headerTitle: string;
  handleConfirm: () => void;
}

const ModalConfirm = ({ show, onHide, headerTitle, handleConfirm }: IProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header style={{ borderBottom: "none" }}>
        <Modal.Title>{headerTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirm;
