import ApexCharts from "apexcharts";
import React, { useCallback, useEffect } from "react";

interface IDataSeries {
  name: string;
  data: number[];
}

interface IStackColumnChart {
  dataSeries: IDataSeries[];
  dataLabels?: string[];
}

const StackColumnChart = React.memo(
  ({ dataSeries, dataLabels }: IStackColumnChart) => {
    const getOptions = useCallback(() => {
      return {
        chart: {
          type: "bar",
          height: 400,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        series: dataSeries,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: dataLabels,
        legend: {
          show: true,
          position: "bottom",
          labels: {
            colors: "#000000",
          },
        },
        colors: ["#311b92", "#43a047"],
        responsive: [
          {
            breakpoint: 1400,
            options: {
              chart: {
                height: 400,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 300,
              },
            },
          },
          {
            breakpoint: 1100,
            options: {
              chart: {
                height: 280,
              },
              legend: {
                fontSize: "9px",
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 280,
              },
              legend: {
                fontSize: "9px",
              },
            },
          },
          {
            breakpoint: 960,
            options: {
              chart: {
                height: 330,
              },
            },
          },
          {
            breakpoint: 810,
            options: {
              chart: {
                height: 300,
              },
            },
          },
          {
            breakpoint: 750,
            options: {
              chart: {
                height: 275,
              },
            },
          },
          {
            breakpoint: 700,
            options: {
              chart: {
                height: 265,
              },
              yaxis: {
                labels: {
                  minWidth: 10,
                },
              },
            },
          },
          {
            breakpoint: 650,
            options: {
              chart: {
                height: 245,
              },
              legend: {
                show: true,
                floating: true,
                fontSize: "8px",
              },
              yaxis: {
                labels: {
                  minWidth: 10,
                },
              },
            },
          },
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 350,
              },
              legend: {
                show: true,
                floating: true,
                fontSize: "13px",
              },
            },
          },
        ],
        yaxis: {
          labels: {
            width: 50,
          },
        },
        fill: {
          colors: ["#311b92", "#43a047"],
        },
      };
    }, [dataSeries, dataLabels]);

    useEffect(() => {
      const element = document.getElementById("stack_column_chart");
      if (!element) {
        return;
      }
      const options = getOptions();
      const chart = new ApexCharts(element, options);
      chart.render();
      // window.dispatchEvent(new Event('resize'))
      return function cleanUp() {
        chart.destroy();
      };
    }, [getOptions]);

    return <div id="stack_column_chart" />;
  }
);

export default StackColumnChart;
