import { AxiosRequestConfig } from "axios";
import { put, post, get, downloadFile } from "./HttpClient";
import utils from "../utils";
import {
  IParamsPatient,
  IDataResetPassword,
  IDataUpdateProfile,
} from "./types";

export function loginApi(user: any, config?: AxiosRequestConfig) {
  return post("auth/v1/admin-login/", user);
}

export function logoutApi(config?: AxiosRequestConfig) {
  return post(
    "auth/v1/logout",
    {},
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function getListPatientAdmin(
  params: IParamsPatient,
  config?: AxiosRequestConfig
) {
  return get(`patient-management-admin/v1/?${utils.getParamsHelp(params)}`, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function downloadCSVPatientApi(
  params: IParamsPatient,
  config?: AxiosRequestConfig
) {
  const today_time = new Date().toISOString();
  return downloadFile(
    `patient-management-admin/v1/user-export-csv/?${utils.getParamsHelp(
      params
    )}`,
    { name_file: `patient_${today_time}`, type_file: "csv" },
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function managePatientApi(
  patient_id: string | number,
  status_patient: string,
  config?: AxiosRequestConfig
) {
  return put(`patient-management-admin/v1/${patient_id}/`, status_patient, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function forgotPasswordApi(email: string, config?: AxiosRequestConfig) {
  return post(`auth/v1/forgot-password-admin/`, { email });
}

export function resetPasswordApi(
  data: IDataResetPassword,
  config?: AxiosRequestConfig
) {
  return post(`auth/v1/reset-password-admin/`, data);
}

export function getAdminProfileApi(config?: AxiosRequestConfig) {
  return get(`account/v1/get-admin-profile/`, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function uploadUserImage(data: any, config?: AxiosRequestConfig) {
  return post("general/v1/upload-user-image/", data, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function updateProfileAdminApi(
  data: IDataUpdateProfile,
  config?: AxiosRequestConfig
) {
  const { userId, ...dataUser } = data;
  return put(`account/v1/${userId}/`, dataUser, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function getPatientProfileApi(
  params: string,
  config?: AxiosRequestConfig
) {
  return get(`patient-management-admin/v1/${params}/`, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function changePasswordApi(data: any, config?: AxiosRequestConfig) {
  return post("auth/v1/change-password/", data, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

// Pharmacist Management

export function getPharmacistModerationApi(
  params: any,
  config?: AxiosRequestConfig
) {
  return get(
    `pharmacy-management-admin/v1/list-pharmacist-pending-refused/?${utils.getParamsHelp(
      params
    )}`,
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function getPharmacistManageApi(
  params: any,
  config?: AxiosRequestConfig
) {
  return get(
    `pharmacy-management-admin/v1/list-pharmacist-active-inactive/?${utils.getParamsHelp(
      params
    )}`,
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function downloadCSVPharmacistApi(
  params: IParamsPatient,
  config?: AxiosRequestConfig
) {
  const today_time = new Date().toISOString();
  return downloadFile(
    `pharmacy-management-admin/v1/user-export-csv/?${utils.getParamsHelp(
      params
    )}`,
    { name_file: `pharmacist_${today_time}`, type_file: "csv" },
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function getPharmacistProfileApi(
  params: string,
  config?: AxiosRequestConfig
) {
  return get(`pharmacy-management-admin/v1/${params}/`, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function managePharmacistApi(
  pharmacist_id: string | number,
  status_pharmacist: any,
  config?: AxiosRequestConfig
) {
  return put(
    `/pharmacy-management-admin/v1/${pharmacist_id}/`,
    status_pharmacist,
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function updateLevelPharmacistApi(
  pharmacist_id: string[] | number[],
  level: string
) {
  return put(
    `pharmacy-management-admin/v1/update-level-pharmacist/`,
    { level, list_id: pharmacist_id },
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function updateStatusPharmacistApi(
  pharmacist_id: string[] | number[],
  status_pharmacist: string | number,
  note?: string,
  config?: AxiosRequestConfig
) {
  return put(
    `pharmacy-management-admin/v1/update-status-pharmacist/`,
    { status: status_pharmacist, list_id: pharmacist_id, note },
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

// Appointment Management
export function getAppointmentManageApi(
  params: IParamsPatient,
  config?: AxiosRequestConfig
) {
  return get(
    `appointment-management-admin/v1/?${utils.getParamsHelp(params)}`,
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}

export function getAppointmentDetailApi(
  appointmentId: string,
  config?: AxiosRequestConfig
) {
  return get(`appointment-management-admin/v1/${appointmentId}/`, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

// Statistical
export function getOverviewStatisticalApi(config?: AxiosRequestConfig) {
  return get(`overview-analytics-admin/v1/overview/`, {
    headers: {
      Authorization: `JWT ${utils.checkAuth()} `,
    },
  });
}

export function getStatisticalAdminApi(
  typeChart: 'appointment-stats' | 'ratting-star' | 'user-stats',
  params: any,
  config?: AxiosRequestConfig
) {
  return get(
    `/statistics-manager-admin/v1/${typeChart}/?${utils.getParamsHelp(params)}`,
    {
      headers: {
        Authorization: `JWT ${utils.checkAuth()} `,
      },
    }
  );
}
