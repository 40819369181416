import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import "../../asset/scss/card_appointment_info.scss";
import { COLOR_APPOINTMENT_STATUS } from "../../constant";
import utils from "../../utils";
import Rating from "../rating/Rating";

const CardAppointmentInfo = ({ appointmentData }: any) => {
  return (
    <div className="card-appointment-info">
      <div className="info-container">
        <div className="container-partner">
          <div className="partner-wrapper">
            <div className="info-partner">
              <img
                src={utils.getUrlImageServer(
                  appointmentData?.pharmacy?.avatar_thumb
                )}
                alt="avatar-user"
                className="img-user"
              />
              <div className="name-user">
                {appointmentData?.pharmacy?.preferred_first_name ||
                  `${appointmentData?.pharmacy?.first_name} ${appointmentData?.pharmacy?.last_name}`}
              </div>
            </div>
            <SwapHorizIcon
              style={{ color: "#8C8C8C", fontSize: 30 }}
              className="reverse-icon"
            />
            <div className="info-partner">
              <img
                src={utils.getUrlImageServer(
                  appointmentData?.patient?.avatar_thumb
                )}
                alt="avatar-user"
              />
              <div className="name-user">
                {appointmentData?.patient?.preferred_first_name ||
                  `${appointmentData?.patient?.first_name} ${appointmentData
                    ?.patient?.last_name || ""}`}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3">
            {appointmentData?.appointment_status === 4 && (
              <Rating rating={appointmentData?.rating} />
            )}
          </div>
        </div>
      </div>
      <div className="card-footer-info">
        <div className="box-info">
          <div className="subtitle-box">Consulting fee</div>
          <span>{appointmentData?.consulting_fee} $/hour</span>
        </div>
        <div className="box-info">
          <div className="subtitle-box">Status</div>
          <div>
            <FiberManualRecordIcon
              style={{
                color: appointmentData?.appointment_status
                  ? COLOR_APPOINTMENT_STATUS[
                      appointmentData?.appointment_status
                    ]?.color
                  : "black",
                fontSize: 12,
              }}
            />
            <span className="ml-1">
              {appointmentData?.appointment_status
                ? COLOR_APPOINTMENT_STATUS[appointmentData?.appointment_status]
                    ?.title
                : "Cancel"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAppointmentInfo;
