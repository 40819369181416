/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { checkArray } from "../../../../../app/utils";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    if (checkArray(url)) {
      for (const path of url) {
        if (checkIsActive(location, path)) {
          return ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`;
        }
      }
      return "";
    }
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/statistical", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/statistical">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/aside/statistical.svg")} />
            </span>
            <span className="menu-text">Statistical</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            ["/patient-manage", "/patient-info"],
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/patient-manage">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/aside/patient.svg")} />
            </span>
            <span className="menu-text">Patient Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            ["/pharmacist-manage", "/pharmacist-info"],
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pharmacist-manage">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/aside/healthicons_medicines.svg")}
              />
            </span>
            <span className="menu-text">Pharmacist Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            ["/appointment-manage", ["/appointment-info"]],
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/appointment-manage">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/aside/appointment.svg")} />
            </span>
            <span className="menu-text">Appointment management</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
