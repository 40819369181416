import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { baseURL } from "../services/HttpClient";

const checkAuth = (location?: any) => {
  let token = sessionStorage.getItem("jwtToken");
  if (!token) {
    token = localStorage.getItem("jwtToken");
  }
  return token ? token : false;
};

export const checkArray = (value: any) => {
  return value && Array.isArray(value) && value.length > 0;
};

export const checkObject = (objCheck: any) => {
  return (
    objCheck &&
    Object.keys(objCheck).length > 0 &&
    objCheck.constructor === Object
  );
};

export const searchByKeyword = (obj: any, keyword: string) => {
  return obj.filter((item: any) => {
    return Object.values(item)
      .map((a: any) => {
        return checkObject(a)
          ? Object.values(a)
              .join(",")
              .toLowerCase()
          : a;
      })
      .join(",")
      .toLowerCase()
      .includes(keyword);
  });
};

export const checkIsUrl = (path: string) => {
  if (!path) {
    return false;
  }
  return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/.test(
    path
  );
};

const scrollToBottom = (idElement: string) => {
  const containerForm = document.getElementById(idElement)!;
  if (containerForm) {
    containerForm.scrollTop = containerForm.scrollHeight;
  }
};

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : null;
};

const getParamsHelp = (params: any) => {
  let paramsArr = [];
  for (const key in params) {
    if (params[key]) {
      paramsArr.push(`${key}=${params[key]}`);
    }
  }
  return paramsArr.join("&");
};

const getColorIcon = (status: string) => {
  let colorIcon;
  switch (status) {
    case "Waiting":
      colorIcon = "#FADB14";
      break;
    case "Ongoing":
      colorIcon = "#1890FF";
      break;
    case "Finish":
    case "Unlock":
      colorIcon = "#4ECB73";
      break;
    case "Cancel":
    case "Lock":
      colorIcon = "#F95D5D";
      break;
    default:
      colorIcon = "#FADB14";
      break;
  }
  return colorIcon;
};

function ImageExist(url: string) {
  try {
    var http = new XMLHttpRequest();

    http.open("HEAD", url, false);
    http.send();

    return http.status !== 404;
  } catch (error) {
    return false;
  }
}

const getUrlImageServer = (path: string | null) => {
  if (!path) {
    return toAbsoluteUrl("/media/users/blank.png");
  }
  if (path && !checkIsUrl(path)) {
    return `${baseURL}${path}`;
  }
  return path;
};

const checkIsPositiveNumber = (value: any) => {
  return value && value > 0;
};

const formatDate = (date: string) => {
  const dateFormat = new Date(date);
  const day = dateFormat.getDate();
  const month = dateFormat.getMonth() + 1;
  const year = dateFormat.getFullYear();
  const newDay = day < 10 ? `0${day}` : day;
  const newMonth = month < 10 ? `0${month}` : month;
  return `${newMonth}/${newDay}/${year}`;
};

const sortByDate = (a: any, b: any) => {
  const dateA = new Date(a.appointment_date);
  const dateB = new Date(b.appointment_date);
  return dateB.getTime() - dateA.getTime();
};

const formatFullName = (first_name: string, last_name: string) => {
  console.log(
    "🚀 ~ file: index.ts ~ line 144 ~ formatFullName ~ first_name",
    first_name
  );
  if (first_name && last_name) {
    if (
      first_name === last_name &&
      (first_name === "New User" || first_name === "Account Deleted")
    ) {
      return first_name;
    }
    return `${first_name} ${last_name}`;
  }
  if (first_name) {
    return first_name;
  }
  if (last_name) {
    return last_name;
  }
  return "_";
};

const formatDataOverviewAnalytic = (data: any) => {
  let dataOverview = [];
  for (const keyData in data) {
    const dataItem: any = {};
    dataItem["key"] = keyData;
    if (keyData === "overview") {
      dataItem["title"] = "All";
    }
    if (keyData === "data_this_month") {
      dataItem["title"] = "This month";
    }
    if (keyData === "data_this_day") {
      dataItem["title"] = "Today";
    }

    dataItem["value"] = [
      {
        patient: {
          data: data[keyData].total_user_patient,
          change_value: data[keyData].compare_patient_user || null,
        },

        pharmacy: {
          data: data[keyData].total_user_pharmacy,
          change_value: data[keyData].compare_pharmacy_user || null,
        },
        iconName: "user_group",
        typeContent: 1,
        titleHeader: "Total users",
        hasUser: keyData === "overview" ? false : true,
      },
      {
        data: data[keyData].total_appointment,
        change_value: data[keyData].compare_appointment || null,
        iconName: "calendar",
        typeContent: 3,
        titleHeader: "Total appointment",
        hasUser: false,
      },
      {
        data: data[keyData].total_revenue,
        change_value: data[keyData].compare_revenue || null,
        iconName: "money",
        typeContent: 2,
        titleHeader: "Revenue",
        hasUser: false,
      },
      {
        patient: {
          data: data[keyData].user_patient_active,
          change_value: data[keyData].compare_patient_active_user || null,
        },
        pharmacy: {
          data: data[keyData].user_pharmacy_active,
          change_value: data[keyData].compare_pharmacy_active_user || null,
        },
        iconName: "active_user",
        typeContent: 1,
        titleHeader: "Active users",
        hasUser: keyData === "overview" ? false : true,
      },
    ];
    dataOverview.push(dataItem);
  }
  return dataOverview;
};

const getNameFile = (url: string) => {
  const urlArr = url.split("/");
  const fileName = urlArr[urlArr.length - 1]?.split(".")[0];
  const typeFile = urlArr[urlArr.length - 1]?.split(".")[1];
  return fileName.length > 50
    ? `${fileName.substring(0, 50)}...${typeFile}`
    : `${fileName}.${typeFile}`;
};

const getFileNameFromUrl = (url: string) => {
  const urlArr = url.split("/");
  const fileName = urlArr[urlArr.length - 1]?.split("?")[0];
  return getNameFile(fileName);
};

const getFullUrlImage = (url: string) => {
  if (url && url.indexOf("http") === -1) {
    return `${baseURL}${url}`;
  }
  return url;
};

const formatPhoneNumber = (phone: string, dial: string) => {
  if (phone && phone.length > 0) {
    return `(${dial}) ${phone}`;
  }
  return "No";
};

export default {
  checkAuth,
  checkArray,
  searchByKeyword,
  scrollToBottom,
  hexToRgb,
  getColorIcon,
  getParamsHelp,
  getUrlImageServer,
  formatDate,
  sortByDate,
  formatFullName,
  checkIsPositiveNumber,
  formatDataOverviewAnalytic,
  getFileNameFromUrl,
  getFullUrlImage,
  formatPhoneNumber,
};
