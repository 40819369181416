import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { COLOR_APPOINTMENT_STATUS } from "../../constant";
import { useHistory } from "react-router-dom";
import "../../asset/scss/card_appointment_history.scss";
import utils from "../../utils";

const CardAppointHistory = ({ listAppointment }: any) => {
  const history = useHistory();

  const handleNavigateAppointment = (appointmentId: number) => {
    history.push(`/appointment-info/${appointmentId}`);
  };

  const renderItem = ({ item, index }: any) => {
    const colorIcon = COLOR_APPOINTMENT_STATUS[item?.appointment_status]?.color;
    return (
      <div
        className="appointment-item"
        key={index}
        onClick={() => handleNavigateAppointment(item.id)}
      >
        <img
          src={utils.getUrlImageServer(item?.pharmacy?.avatar_thumb)}
          alt="avatar-pharmacist"
          className="avatar-pharmacist"
        />
        <div className="list-title-field">
          <div className="field-text">Pharmacist:</div>
          <div className="field-text">Appointed time:</div>
          <div className="field-text">Status:</div>
        </div>
        <div className="list-description">
          <div className="field-text pharmacist">
            {item?.pharmacy?.preferred_first_name}
          </div>
          <div className="field-text appoint-time">
            {item?.appointment_date
              ? utils.formatDate(item?.appointment_date)
              : "No"}
          </div>
          <div className="field-text ">
            <FiberManualRecordIcon style={{ color: colorIcon, fontSize: 12 }} />
            <span className="ml-1">
              {COLOR_APPOINTMENT_STATUS[item?.appointment_status]?.title}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card-appointment-history bg-white">
      <div className="header-card">
        <div className="title-header">Appointment history</div>
      </div>
      <div className="content-card">
        {listAppointment.length > 0 &&
          listAppointment.map((item: any, index: number) =>
            renderItem({ item, index })
          )}
        {listAppointment.length === 0 && (
          <div className="mt-6 justify-content-center d-flex">
            <p className="h4 font-weight-bolder">No data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardAppointHistory;
